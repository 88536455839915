import React from "react";
import { Modal, Form, Input, Col, Checkbox, Row } from "antd";
import { ApolloError } from "apollo-boost";
import "./ClassDlg.scss";
import { appStore } from "../../../store/AppStore";
import { observer } from "mobx-react";
import { showError, showSuccess } from "../../../common/Utils";

export const ClassDlg = observer(() => {
  const [form] = Form.useForm();
  const { editGroup } = appStore.teach.schoolsStore;
  editGroup.form = form;

  const save = () => {
    form.validateFields().then((values) => {
      editGroup
        .save()
        .then((res) => {
          showSuccess();
        })
        .catch((err: ApolloError) => {
          if (err.graphQLErrors) {
            showError();
          }
        });
    });
  };

  const onChange = (e) => {
    const { checked, value } = e.target;
    const { schoolAllStudents, selected } = editGroup;

    const allChildIds = schoolAllStudents.map((x) => x.childId);
    let checkedItems = selected.filter((x) => allChildIds.includes(x));

    if (checked) {
      checkedItems = [...checkedItems, value];
    } else {
      checkedItems = checkedItems.filter((x) => x !== value);
    }

    let gradeItems = editGroup.grades.filter((x) => {
      const gradeStudents = schoolAllStudents.filter((xx) => xx.grade === x);

      if (gradeStudents.length === 0) return false;

      return gradeStudents.filter((x) => !checkedItems.includes(x.childId)).length === 0;
    });

    editGroup.selected = Array.from(new Set([...checkedItems, ...gradeItems]));
  };

  const onGradeChange = (e) => {
    const { checked, value } = e.target;
    const gradeStudents = editGroup.schoolAllStudents.filter((xx) => xx.grade === value);
    const childIds = gradeStudents.map((x) => x.childId);
    let newItems: any[] = [];
    if (checked) {
      newItems = [...editGroup.selected, ...childIds, value];
    } else {
      newItems = editGroup.selected.filter((x) => x !== value && !childIds.includes(x));
    }

    editGroup.selected = Array.from(new Set(newItems));
  };

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 18 },
  };
  return (
    <Modal
      visible={editGroup.visible}
      maskClosable={false}
      onOk={save}
      confirmLoading={editGroup.saving}
      onCancel={() => editGroup.close()}
      width={900}
      okText="Complete"
      okButtonProps={{ disabled: editGroup.name.trim().length === 0 || editGroup.selected.length === 0 }}
      getContainer={false}
      className="class-dlg"
      bodyStyle={{ paddingBottom: 0 }}
    >
      <Form form={form} {...layout} initialValues={{ name: editGroup.name }}>
        <Form.Item
          label="Group Name"
          name="name"
          rules={[{ required: true, message: "Please input group name" }]}
          extra="You may want to define a group name you could use in the Challenge Event name (ie. 5th Grade Reading
          Challenge)."
        >
          <Input autoComplete="new-password" maxLength={50} onChange={(e) => (editGroup.name = e.target.value)} />
        </Form.Item>
        <Form.Item label="Choose Students">
          <div className="students-box">
            <Input.Search
              value={editGroup.keyword}
              onChange={(e) => (editGroup.keyword = e.target.value)}
            ></Input.Search>
            <Checkbox.Group style={{ width: "100%" }} value={editGroup.selected}>
              {editGroup.grades
                .filter((x) => editGroup.schoolStudents.filter((xx) => xx.grade === x).length > 0)
                .map((g, i) => (
                  <div className="grade-box" key={g}>
                    <div className="grade-title">
                      <Checkbox value={g} onChange={onGradeChange}>
                        <span className="grade-title">{g} Grade</span>
                      </Checkbox>
                    </div>
                    <div className="grade-students">
                      <Row>
                        {editGroup.schoolStudents
                          .filter((x) => x.grade === g)
                          .map((x) => (
                            <Col xs={12} sm={8} md={6} key={x.childId}>
                              <Checkbox value={x.childId} onChange={onChange}>
                                {x.name}
                              </Checkbox>
                            </Col>
                          ))}
                      </Row>
                    </div>
                  </div>
                ))}
            </Checkbox.Group>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
});
