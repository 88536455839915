import { makeAutoObservable, autorun, runInAction, action } from "mobx";

export class ChallengeEvent {
  id;
  type;
  name;
  customName;
  title;
  start;
  shareCode;
  end;
  participant;
  students: any[];

  constructor(model) {
    makeAutoObservable(this);
    this.id = model.id;
    this.name = model.name;
    this.customName = model.customName;
    this.title = model.title;
    this.start = model.start;
    this.end = model.end;
    this.participant = model.participant;
    this.students = model.students;
    this.shareCode = model.shareCode;
  }
}
