import React, { useEffect, useState } from "react";
import "./Account.scss";
import { FrCard } from "../../components/FrCard";
import { useMutation } from "@apollo/react-hooks";
import { meGql } from "../../graphql/me";
import { Modal, Tag } from "antd";
import { AddCardDlg } from "./AddCardDlg";
import { showSuccess } from "../../common/Utils";
import { appStore } from "../../store/AppStore";
import { parent } from "../../store/domains/Parent";
import { observer } from "mobx-react";
const { confirm } = Modal;

export const Payments = observer(() => {
  const [showAdd, setShowAdd] = useState(false);
  const [delPayMethod] = useMutation(meGql.delPayMethod);

  useEffect(() => {
    parent.loadPayMethods();
  }, []);

  const onDel = (x) => {
    confirm({
      content: "Confirm deletion.",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      icon: null,
      centered: true,
      onOk() {
        console.log("OK");
        delPayMethod({
          variables: {
            id: x.id,
          },
        }).then(() => {
          showSuccess();
          parent.loadPayMethods();
        });
      },
    });
  };

  const onAdded = () => {
    setShowAdd(false);
    parent.loadPayMethods();
  };

  return (
    <FrCard title="Payment Method" onAdd={() => setShowAdd(true)}>
      {parent.payMethods.map((x: any) => (
        <div className="payment" key={x.id}>
          <div className="info">
            <div className="line1">
              {x.brand} **** {x.last4}{" "}
              {x.isDefault && (
                <Tag style={{ marginLeft: 24 }} color="processing">
                  default
                </Tag>
              )}
            </div>
            <div className="line2">{x.name || appStore.me.name}</div>
          </div>
          <div className="del" onClick={() => onDel(x)}></div>
        </div>
      ))}
      {showAdd && <AddCardDlg onCancel={() => setShowAdd(false)} onDone={onAdded} />}
    </FrCard>
  );
});
