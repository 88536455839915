import Auth from "@aws-amplify/auth";
import { message } from "antd";
import { makeAutoObservable, autorun, runInAction, action } from "mobx";
import { Api } from "../common/Api";
import { appStore, AppStore } from "./AppStore";

export class InviteStore {
  store: AppStore;
  id: string = "";
  loading = false;
  saving = false;
  inviteInfo: any;

  constructor(store: AppStore) {
    makeAutoObservable(this, {
      store: false,
    });
    this.store = store;
  }

  @action
  loadInvite(id) {
    this.loading = true;
    this.id = id;
    Api.invite
      .findInviteEmail(id)
      .then((res) => {
        runInAction(() => {
          this.inviteInfo = res.data.data;
        });
      })
      .finally(() => (this.loading = false));
  }

  @action
  initPwd(pwd) {
    this.saving = true;
    return Api.invite
      .inviteInitPwd(this.id, pwd)
      .then((res) => {
        console.log("initPwd", res.data);

        return Auth.signIn(this.inviteInfo.email, pwd)
          .then((user) => {
            appStore.isLogin = true;
            appStore.loadMe();
            return true;
          })
          .catch((err) => {
            message.error(err.message);
            return false;
          });
      })
      .finally(() => (this.saving = false));
  }
}
