import React, { useState } from "react";
import "./Admin.scss";
import { FrCard } from "../../components/FrCard";
import { RightOutlined } from "@ant-design/icons";
import { FeedbackDlg } from "./FeedbackDlg";
import { useHistory } from "react-router";
import { observer } from "mobx-react";
import { appStore } from "../../store/AppStore";

export const Management = observer(({ title = "Management" }) => {
  const [showFeedback, setShowFeedback] = useState(false);
  const history = useHistory();

  return (
    <FrCard
      title={title}
      style={{ height: 400 }}
      bodyStyle={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: 40,
      }}
    >
      <div className="itemBtn itemBtn1" onClick={() => appStore.admin.fundraising.show()}>
        <div className="icon"></div>
        <div style={{ flexGrow: 1 }}>Start a New Fundraiser</div>
        <RightOutlined style={{ fontSize: "10px", color: "#828587" }} />
      </div>

      <div className="itemBtn itemBtn2" onClick={() => history.push("/fund/history")}>
        <div className="icon"></div>
        <div style={{ flexGrow: 1 }}>Fundraising History</div>
        <RightOutlined style={{ fontSize: "10px", color: "#828587" }} />
      </div>

      {showFeedback && <FeedbackDlg onClose={() => setShowFeedback(false)} />}
    </FrCard>
  );
});
