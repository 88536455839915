import React from "react";
import { InputNumber } from "antd";
import { isMobile } from "react-device-detect";

export const MoneyInput: React.FC<{
  value?: any;
  max?: number;
  onChange?: (value: any) => void;
  style?: any;
  className?: any;
}> = ({ value, style, max = 999999, className, onChange }) => {
  return (
    <div className="FmCard" style={style}>
      <div style={{ marginRight: 8, fontSize: 16, display: "inline-block" }}>$</div>
      {isMobile ? (
        <InputNumber type="number" value={value} min={1} max={max} precision={2} onChange={onChange} style={style} />
      ) : (
        <InputNumber
          value={value}
          min={1}
          max={max}
          precision={2}
          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          parser={(value) => parseFloat(`${value}`.replace(/\$\s?|(,*)/g, ""))}
          onChange={onChange}
          style={style}
        />
      )}
    </div>
  );
};
