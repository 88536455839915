import { makeAutoObservable, autorun, runInAction, action, computed } from "mobx";
import { EditChildStore } from "./EditChildStore";
import { ParentFundraisingsStore } from "./Fundraising/FundraisingsStore";
import { parent } from "../domains/Parent";
import { DonateStore } from "./Fundraising/DonateStore";
import { InviteDonateStore } from "./Fundraising/InviteDonateStore";
import { Modal } from "antd";
import { Api } from "../../common/Api";
import { showSuccess } from "../../common/Utils";

export class ParentStore {
  store = null;
  editChild: EditChildStore;
  fundraisingsStore: ParentFundraisingsStore;
  donateStore: DonateStore;
  inviteDonateStore: InviteDonateStore;

  constructor(store) {
    makeAutoObservable(this, {
      store: false,
    });
    this.store = store;
    this.editChild = new EditChildStore(this);
    this.fundraisingsStore = new ParentFundraisingsStore(this);
    this.donateStore = new DonateStore(this);
    this.inviteDonateStore = new InviteDonateStore(this);
  }

  @action
  update({ children, schools, donates }) {
    parent.update({ children, schools, donates });
    this.fundraisingsStore.checkActiveSchoolId();
  }

  @action delChild(child) {
    Modal.confirm({
      title: "Confirm Deletion & Loss of Community Access",
      content: `You may lose access to this child's school Community post deletion, if you don't have another child in the same school.
      You could consider edit any information in FooMoo app instead.`,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      icon: null,
      centered: true,
      onOk: () => {
        Api.parent.delChild(child.childId).then(() => {
          showSuccess();
          parent.loadParent();
        });
      },
    });
  }
}
