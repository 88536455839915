import moment from "moment";
import currency from "currency.js";
import { Modal, notification } from "antd";
import { ApolloError } from "apollo-boost";

import React, { useLayoutEffect, useState } from "react";
import { isMobile } from "react-device-detect";

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};

export const fmtMoney = (val, precision = 0) => currency(val, { precision }).format(true);

export const fmtDate = (val) => moment(val).format("MMM D, YYYY");

export const timeDuration = ({ start, end }) => {
  return `${moment(start).format("MMM D")} - ${moment(end).format("MMM D, YYYY")}`;
};

export const isArrayInArray = (arr1: string[], arr2: string[]) => {
  for (let a of arr1) {
    if (arr2.includes(a)) return true;
  }
  return false;
};

export const defaultLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

export const defaultQLError = (err: ApolloError) => {
  if (err.graphQLErrors) {
    showError();
  }
};

export const showSuccess = (content = "") => {
  const modal = Modal.success({ content, okButtonProps: { style: { display: "none" } }, centered: true });
  setTimeout(() => {
    modal.destroy();
  }, 2000);
};

export const showError = (msg = "") => {
  const modal = Modal.error({
    okText: "Close",
    centered: true,
    content: msg || "Unable to Reach Server. Please try again later.",
  });
};

export const rpx = (px) => (isMobile ? `${(px * 100) / 750}vw` : px);

export const Utils = {
  fmtDate,
  fmtMoney,
  hasKey: (obj, key) => Object.keys(obj).includes(key),
};
