import { observer } from "mobx-react";
import React from "react";
import { SchoolsBar } from "../../../components/SchoolsBar";
import { appStore } from "../../../store/AppStore";
import { teacher } from "../../../store/domains/Teacher";

export const TeachSchoolsBar = observer(({ btnText, onBtn }: any) => {
  const onSchool = (id) => {
    appStore.teach.schoolsStore.setActiveSchool(id);
  };

  return (
    <SchoolsBar
      schools={teacher.schools}
      curSchoolId={appStore.teach.schoolsStore.activeSchoolId}
      onBtn={onBtn}
      onSchool={onSchool}
      content={btnText}
      showAdd
    ></SchoolsBar>
  );
});
