import React from "react";
import "./AuthLayout.scss";

export const AuthLayout = ({ children }) => {
  return (
    <div className="auth-layout">
      <div className="container">
        <div className="header">
          <div className="logo"></div>
          <a href="https://www.foo-moo.com/" target="_blank" rel="noopener noreferrer" className="about">
            About
          </a>
        </div>
        {children}
      </div>
    </div>
  );
};
