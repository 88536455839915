import { makeAutoObservable, action, computed, observable } from "mobx";
import moment from "moment";
import { Api } from "../../common/Api";
import { fundraiser } from "../domains/Fundraiser";
import { AdminStore } from "./AdminStore";

export class FundSchoolsStore {
  activeSchoolId;
  activeFundIds = observable({});

  store: AdminStore;

  constructor(store: AdminStore) {
    makeAutoObservable(this, {
      store: false,
      inProgress: computed,
    });
    this.store = store;
  }

  get school() {
    return fundraiser.schools.find((x) => x.id === this.activeSchoolId);
  }

  get inProgress() {
    return this.school?.inProgressAdminFund || [];
  }

  get activeFundId() {
    return this.activeFundIds[this.activeSchoolId];
  }

  get activeFund() {
    return this.inProgress?.find((x) => x.id === this.activeFundId);
  }

  @action
  setActiveFund(id) {
    this.activeFundIds[this.activeSchoolId] = id;
  }

  @action
  async setActiveSchool(id) {
    this.activeSchoolId = id;
    if (id) {
      console.log("load loadFundraisings", this.school, id);
      await this.school?.loadAdminFundraisings();
    }
    if (!this.activeFundId && this.inProgress.length > 0) {
      this.setActiveFund(this.inProgress[0].id);
    }
  }

  @action
  checkActiveSchoolId() {
    if (fundraiser.schools.length === 0) {
      this.setActiveSchool(null);
    } else if (fundraiser.schools.length === 1) {
      this.setActiveSchool(fundraiser.schools[0].id);
    } else {
      let schools = fundraiser.schools
        .filter((x) => x.inProgressAdminFund.length > 0)
        .sort((a, b) => moment(a.adminFundraisings[0].end).valueOf() - moment(b.adminFundraisings[0].end).valueOf());
      if (schools.length > 0) {
        this.setActiveSchool(schools[0].id);
        this.setActiveFund(schools[0].inProgressAdminFund[0].id);
      }
    }
  }

  @action
  delFund(id) {
    return Api.fund.delFundraiser(id).then(async (res) => {
      await this.store.schoolsStore.school?.loadAdminFundraisings();
    });
  }
}
