import React, { useEffect, useState } from "react";
import { MenuOutlined } from "@ant-design/icons";
import { Drawer, Menu } from "antd";
import "./MobileMenu.scss";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { appStore } from "../../store/AppStore";
import { teacher } from "../../store/domains/Teacher";
const { SubMenu } = Menu;

export const MobileMenu = ({ logout, me }) => {
  const [showMenu, setShowMenu] = useState(false);

  const { firstName, isTeacher, isAdmin, isParent } = me || {};

  const history = useHistory();
  const location = useLocation();
  const [curMenu, setCurMenu] = useState("/");

  useEffect(() => {
    setCurMenu(location.pathname);
  }, [location]);

  const onMenu = (e) => {
    setShowMenu(false);
    if (e.key === "logout") {
      appStore.logout().then(() => {
        history.push("/");
        window.location.reload();
      });
    } else if (e.key === "newevent") {
      appStore.teach.challenge.show();
    } else if (e.key === "newfund") {
      appStore.admin.fundraising.show();
    } else {
      history.push(e.key);
    }
  };

  return (
    <div className="mobile-menus">
      <MenuOutlined style={{ color: "#000", fontSize: "24px" }} onClick={() => setShowMenu(true)} />
      <Drawer
        title={<div className="moblie-menu-logo"></div>}
        placement="right"
        closable={true}
        onClose={() => setShowMenu(false)}
        visible={showMenu}
        height="auto"
        headerStyle={{
          height: 48,
          padding: "0 15px",
          display: "flex",
          alignItems: "center",
          borderBottom: "none",
        }}
        bodyStyle={{
          padding: 0,
        }}
      >
        <div
          style={{
            height: 10,
            borderBottom: "solid 1px #EAEBEC",
            margin: "0 10px",
          }}
        />
        <Menu mode="inline" onClick={onMenu} selectedKeys={[curMenu]}>
          {(isTeacher || isAdmin) && (
            <Menu.Item key="/" className="menu">
              Home
            </Menu.Item>
          )}
          {isTeacher && teacher.schools.length > 0 && (
            <SubMenu key="/teach" title="Challenge Event" className="menu">
              <Menu.Item key={`newevent`} className="menu">
                New Event
              </Menu.Item>
              <Menu.Item key={`/teach/events`} className="menu">
                Event List
              </Menu.Item>
              <Menu.Item key={`/teach/classes`} className="menu">
                Student List
              </Menu.Item>
            </SubMenu>
          )}
          {isAdmin && (
            <SubMenu key="/fund" title="Fundraiser" className="menu">
              <Menu.Item key={`/fund/active`} className="menu">
                Manage Active Fundraiser
              </Menu.Item>
              <Menu.Item key={`newfund`} className="menu">
                Start New Fundraiser
              </Menu.Item>
              <Menu.Item key={`/fund/history`} className="menu">
                Fundraising History
              </Menu.Item>
              {isParent && (
                <Menu.Item key="/fundraiser" className="menu">
                  Participate in Fundraiser
                </Menu.Item>
              )}
            </SubMenu>
          )}
          {isParent && !isAdmin && (
            <Menu.Item key="/fundraiser" className="menu">
              Fundraiser
            </Menu.Item>
          )}
          <Menu.Item key="/account" className="menu">
            Account
          </Menu.Item>
          <Menu.Item key="logout" className="menu">
            Log Out
          </Menu.Item>
        </Menu>
      </Drawer>
    </div>
  );
};
