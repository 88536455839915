import React from "react";
import { Row, Col, Button, Layout, List, Typography, Modal } from "antd";
import { PlusCircleOutlined, DeleteOutlined, FormOutlined } from "@ant-design/icons";
import { TeachSchoolsBar } from "../components/TeachSchoolsBar";
import "../teacher.scss";
import { FmCard } from "../../../components/FmCard";
import { appStore } from "../../../store/AppStore";
import { observer } from "mobx-react";
import { showSuccess } from "../../../common/Utils";
const { confirm } = Modal;

export const Classes = observer(() => {
  const allSchool = { classId: "", name: "All School" };

  const { group, editGroup } = appStore.teach.schoolsStore;
  const { classes = [] } = appStore.teach.schoolsStore.school || {};
  const { students = [] } = group;

  const onDel = () => {
    confirm({
      content: "Confirm deletion.",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      icon: null,
      centered: true,
      onOk() {
        group.delClass()?.then(() => {
          showSuccess();
        });
      },
    });
  };

  return (
    <Layout className="teach">
      <Layout.Header className="headerWrap" style={{ position: "fixed", zIndex: 1000, width: "100%" }}>
        <TeachSchoolsBar btnText="Create New Group" onBtn={() => editGroup.show()} />
      </Layout.Header>
      <Layout.Content style={{ marginTop: 64 }}>
        <div className="container body-wrapper">
          <FmCard style={{ marginTop: 20 }}>
            <Layout className="class-page">
              <Row gutter={32}>
                <Col xs={24} md={7} className="side">
                  {[allSchool, ...classes].map((c, i) => (
                    <Button
                      block
                      size="large"
                      type={(group.isSchool && 0 === i) || group.id === c.classId ? "primary" : "default"}
                      key={c.classId || ""}
                      onClick={() => group.active(i === 0, c.classId)}
                    >
                      {c.name}
                    </Button>
                  ))}

                  <Button
                    block
                    size="large"
                    icon={<PlusCircleOutlined />}
                    style={{ color: "#0CBEFF" }}
                    onClick={() => editGroup.show()}
                  >
                    Creat New Group
                  </Button>
                </Col>
                <Col xs={24} md={17}>
                  <List
                    bordered
                    style={{ height: 528 }}
                    rowKey="childId"
                    size="small"
                    header={
                      <div className="headerBar">
                        {group.isSchool ? <span></span> : <div style={{ width: 32 }}></div>}
                        <Typography.Text strong>
                          {group.name} ({students.length})
                        </Typography.Text>
                        {group.isSchool ? (
                          <span></span>
                        ) : (
                          <Button type="link" danger icon={<DeleteOutlined />} onClick={onDel}></Button>
                        )}
                      </div>
                    }
                    footer={
                      <div style={{ textAlign: "center" }}>
                        {!group.isSchool && (
                          <Button
                            icon={<FormOutlined />}
                            style={{ color: "#0CBEFF" }}
                            onClick={() => editGroup.edit(group.group)}
                          >
                            Edit
                          </Button>
                        )}
                      </div>
                    }
                    renderItem={(item: any) => <List.Item actions={[item.grade]}>{item.name}</List.Item>}
                    dataSource={students}
                  />
                </Col>
              </Row>
            </Layout>
          </FmCard>
        </div>
      </Layout.Content>
    </Layout>
  );
});
