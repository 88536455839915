export const FrTypes = [
  "Armchair Fundraiser (fundraising only)",
  "Student Enrichment Fundraiser",
  "Donation per Goal Achieved Fundraiser",
];

export const GradeList = [
  "Infant",
  "Toddler",
  "Pre-K",
  "Kindergarten",
  "1st",
  "2nd",
  "3rd",
  "4th",
  "5th",
  "6th",
  "7th",
  "8th",
  "9th",
  "10th",
  "11th",
  "12th",
];
