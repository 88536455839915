import gql from "graphql-tag";

const engActivities = gql`
  query engActivities($orgId: String!) {
    engActivities(orgId: $orgId) {
      id
      type
      name
      customName
      title
      start
      end
      shareCode
      participant {
        title
      }
      students {
        id
        childId
        status
        child {
          name
        }
      }
    }
  }
`;

const engActivity = gql`
  query engActivity($id: String!) {
    engActivity(id: $id) {
      id
      type
      name
      customName
      title
      description
      start
      end
      shareCode
      canComplete
      participant {
        title
      }
      goal {
        enabled
        description
        items
      }
      workSubmission {
        enabled
        items
      }
      students {
        id
        childId
        workId
        workTitle
        status
        hasMsg
        child {
          name
        }
      }
    }
  }
`;

const challengeWork = gql`
  query challengeWork($challengeId: String!, $workId: String!) {
    challengeWork(challengeId: $challengeId, workId: $workId) {
      PostId
      Title
      Content {
        sectionType
        sectionContent
      }
      comments {
        CommentId
        Comment
        CreatedAt
        CreatedBy
        ResourceId
        TargetId
        userName
      }
    }
  }
`;

const engParticipants = gql`
  query engParticipants($orgId: String!) {
    engParticipants(orgId: $orgId) {
      typeId
      type
      title
      grades
      count
    }
  }
`;

const engTool = gql`
  {
    engTool {
      activityTypes {
        category
        id
        data
      }
      activities {
        id
        activityType
        activityName
        description
        grades
        goalsTeacher
        goalsGradeAll
        goalsItems {
          title
          items
        }
        goalsStudent
        goalsNone
        parentHelp
      }
      workSubmissions {
        id
        activityType
        title
        description
        grades
      }
    }
  }
`;

const saveEngActivity = gql`
  mutation saveEngActivity($input: EngActivityInput!) {
    saveEngActivity(input: $input)
  }
`;

const delEngActivity = gql`
  mutation delEngActivity($id: String!) {
    delEngActivity(id: $id)
  }
`;

const completeEngActivity = gql`
  mutation completeEngActivity($id: String!) {
    completeEngActivity(id: $id)
  }
`;

const remindEngActivity = gql`
  mutation remindEngActivity($id: String!, $status: Int!) {
    remindEngActivity(id: $id, status: $status)
  }
`;

export const engGql = {
  engParticipants,
  saveEngActivity,
  delEngActivity,
  completeEngActivity,
  remindEngActivity,
  engActivities,
  engActivity,
  challengeWork,
  engTool,
};
