import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Row, Col, Space, Input, InputNumber } from "antd";
import "./DonateBox.scss";
import moment from "moment";
import { MoneyInput } from "../../../../components/MoneyInput";

interface DonateBoxProp {
  info?: string;
  amount?: number;
  th?: boolean;
  input?: boolean;
  active?: boolean;
  onDonate?: (number) => void;
}

const DonateAmount = ({ amount, th }: any) => (
  <div className="DonateAmount">
    {!th && <div style={{ marginRight: 8 }}>$</div>}
    <div style={{ fontSize: "28px", lineHeight: "25px" }}>{amount}</div>
    {/* {!!th && <div>th</div>} */}
  </div>
);

export const DonateBox: React.FC<DonateBoxProp> = observer(({ active, info, children }) => {
  return (
    <div className={`DonateBox ${active ? "active" : ""}`}>
      <div className="info">{info}</div>
      <div className="body">{children}</div>
    </div>
  );
});

export const DonateBoxView: React.FC<DonateBoxProp> = observer(({ info, amount, th }) => {
  return (
    <DonateBox info={info}>
      <DonateAmount amount={amount} th={th} />
    </DonateBox>
  );
});

export const DonateBoxEdit: React.FC<DonateBoxProp> = observer(({ active, info, amount, input, onDonate }) => {
  const [val, setVal] = useState(amount);

  return (
    <DonateBox info={info} active={active}>
      {input ? (
        <MoneyInput
          value={val} max={9999.99}
          onChange={(value) => setVal(Number(value))}
          style={{ color: "#191a1d", fontSize: "28px", fontWeight: 600, textAlign: "center" }}
        ></MoneyInput>
      ) : (
        <DonateAmount amount={amount} />
      )}
      <div className={`fcc btn ${input ? "input-btn" : ""}`} onClick={() => onDonate && onDonate(val)}>
        Donate
      </div>
    </DonateBox>
  );
});

export const DonateViewList = ({ stats, end, donateCount }) => {
  const items = [
    { info: `This fundraiser ends on ${moment(end).format("MMM D, YYYY")}`, amount: stats.userDonated / 100.0 },
    { info: "Contribution from your extended family", amount: (stats.extended || 0) / 100.0 },
    {
      info: "Number of donations made. Encourage others to join!",
      amount: donateCount,
      th: true,
    },
  ];
  return (
    <Row gutter={24} className="DonateViewList">
      {items.map((x) => (
        <Col xs={24} md={8} key={x.info}>
          <DonateBoxView info={x.info} amount={x.amount} th={x.th}></DonateBoxView>
        </Col>
      ))}
    </Row>
  );
};

export const DonateEditList = ({ suggested, onDonate }) => {
  const [curIndex, setCurIndex] = useState<number | null>(null);

  const items = [
    { info: "Suggested Donation", amount: suggested },
    {
      info: "Any Amount You Decide",
      amount: null,
      input: true,
    },
  ];
  return (
    <Row gutter={24} className="DonateEditList">
      {items.map((x, i) => (
        <Col xs={12} md={8} key={i + ""} onClick={() => setCurIndex(i)}>
          <DonateBoxEdit
            active={i === curIndex}
            info={x.info}
            amount={x.amount}
            input={x.input}
            onDonate={(amount) => onDonate(amount)}
          />
        </Col>
      ))}
    </Row>
  );
};
