import React, { useState, useEffect } from "react";
import { Layout } from "antd";
import { useQuery } from "@apollo/react-hooks";
import { meGql } from "../../graphql/me";
import "./Home.scss";
import { TeacherIndex } from "./TeacherIndex";
import { TeachSchoolsBar } from "../teacher/components/TeachSchoolsBar";

export const Home = () => {
  const [curType, setCurType] = useState("");
  const [curId, setCurId] = useState("");
  const { data: { me } = { me: { isTeacher: false, schools: [] } } } = useQuery<any>(meGql.me);

  // useEffect(() => {
  //   if (me.schools.length > 0) {
  //     setCurId("school");
  //     setCurId(me.schools[0].id);
  //   }
  // }, [me]);

  const onChange = (id, type) => {
    setCurType(type);
    setCurId(id);
  };
  return (
    <Layout className="home">
      <Layout.Header className="headerWrap">{/* <TeachSchoolsBar /> */}</Layout.Header>
      <Layout.Content>
        <div className="container">
          <TeacherIndex></TeacherIndex>
        </div>
      </Layout.Content>
    </Layout>
  );
};
