import { makeAutoObservable, runInAction, action } from "mobx";
import { Api } from "../../common/Api";
import { showSuccess, defaultQLError } from "../../common/Utils";
import { Child } from "../domains/Child";
import { parent } from "../domains/Parent";
import { ParentStore } from "./ParentStore";
import { Modal } from "antd";

export class EditChildStore {
  child: Child;
  schools: any[] = [];
  loadingSchool = false;
  grades: string[] = [];
  visible = false;
  keyword = "";
  saving = false;

  store: ParentStore;

  model = {
    schoolId: null,
    firstName: "",
    grade: "",
  };

  constructor(store: ParentStore) {
    makeAutoObservable(this, {
      store: false,
    });
    this.store = store;
    this.child = new Child();
  }

  @action edit() {
    this.visible = true;
    this.schools = [];
    this.setKeyword("");
    this.model = {
      schoolId: null,
      firstName: "",
      grade: "",
    };
  }

  get canSave() {
    const { schoolId, firstName, grade } = this.model;
    return !!schoolId && !!firstName && !!grade;
  }

  @action
  setKeyword(val) {
    this.keyword = val;

    if (!this.keyword.trim()) {
      this.schools = [];
      this.loadingSchool = false;
      return;
    }

    this.loadingSchool = true;
    Api.searchSchools(val)
      .then((res) => {
        runInAction(() => {
          this.schools = res.data.searchSchools;
        });
      })
      .finally(() => {
        this.loadingSchool = false;
      });
  }

  @action onSchoolChange(schoolId) {
    this.model.schoolId = schoolId;
    this.model.grade = "";
    const s = this.schools.find((x) => x.id === schoolId);
    this.grades = s ? [...s.grades] : [];
  }

  @action onValuesChange(values) {
    this.model = { ...this.model, ...values };
  }

  save(values) {
    this.saving = true;
    return Api.parent
      .saveChild(values)
      .then((res) => {
        const isNewSchool = !parent.children.find((x) => x.school?.id === this.model.schoolId);

        console.log("ss", isNewSchool, parent.schools);

        parent.loadParent();
        this.close();
        if (isNewSchool) {
          Modal.success({
            title: "Verification in Progress",
            content: `Upon Community Director confirmation, you will have access to this school's fundraising activities and other parents in this Community via the FooMoo app. Please refresh your browser to see the update. Thank you for your patience.`,
            okText: "Close",
            okType: "default",
            centered: true,
          });
        } else {
          showSuccess();
        }
      })
      .catch((err) => defaultQLError(err))
      .finally(() => (this.saving = false));
  }
  close() {
    this.visible = false;
  }
}
