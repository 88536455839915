import React, { useEffect, useState } from "react";
import { Input } from "antd";
import "./ChildPanel.scss";
import { CheckOutlined, FormOutlined, CloseCircleOutlined, SaveOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";
import { parent } from "../../store/domains/Parent";

const Goal = observer(({ goal, onChange, onComplete }) => {
  const [edit, setEdit] = useState(false);
  const [text, setText] = useState("");

  const onEdit = () => {
    if (goal.completed) return;

    setText(goal.description);
    setEdit(true);
  };

  const onSave = () => {
    setEdit(false);
    onChange(text);
  };

  return edit ? (
    <div className="fund-child-goals-item">
      <Input size="small" value={text} onChange={(e) => setText(e.target.value)}></Input>
      <SaveOutlined style={{ margin: "0 16px" }} onClick={onSave} />
      <CloseCircleOutlined onClick={() => setEdit(false)} />
    </div>
  ) : (
      <div className="fund-child-goals-item">
        <div className="fund-child-goal">{goal.description || ""}</div>
        <CheckOutlined className={goal.completed ? "completed" : ""} style={{ margin: "0 16px" }} onClick={onComplete} />
        <FormOutlined onClick={onEdit} />
      </div>
    );
});

export const ChildPanel = observer(({ student, onChange }) => {
  const onGoalChange = (index, val) => {
    student.goals[index].description = val;
    onChange(student.goals);
  };

  const onComplete = (index) => {
    if (!!(student.goals[index].description || "").trim() && !student.goals[index].completed) {
      student.goals[index].completed = true;
      onChange(student.goals);
    }
  };

  const onAdd = () => {
    student.goals = [...student.goals, { completed: false, description: "" }];
  };

  return (
    <div className="fund-child-goals">
      <div className="fund-child-goals-item">
        <strong style={{ color: "#191A1D", width: "100%" }}>{parent.getChildName(student.childId)}</strong>
        <PlusCircleOutlined style={{ color: "#0CBEFF" }} onClick={onAdd} />
      </div>

      {student.goals.map((x, i) => (
        <Goal key={i} goal={x} onChange={(val) => onGoalChange(i, val)} onComplete={() => onComplete(i)}></Goal>
      ))}
    </div>
  );
});
