import React, { useEffect } from "react";
import "./Donate.scss";
import { observer } from "mobx-react-lite";
import { Alert, Button, Card, Form, Input, InputNumber, Result, Spin } from "antd";
import CardSection from "../account/CardSection";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { donateInviteStore } from "../../store/DonateInviteStore";
import { MoneyInput } from "../../components/MoneyInput";
import { fmtMoney, showError, showSuccess } from "../../common/Utils";
import { Api } from "../../common/Api";

export const DonateForm = observer(() => {
  const stripe = useStripe();
  const elements = useElements();
  const [form] = Form.useForm();

  const { id, loading, inviteInfo } = donateInviteStore;

  const onDonate = () => {
    if (!stripe || !elements) {
      showError();
      return;
    }

    form.validateFields().then(async (values) => {
      donateInviteStore.saving = true;

      if (inviteInfo.perGoal) {
        const res = await Api.donateInvite.perGoal({ id, ...values.perGoal, cardHolder: values.billingDetails.name });
        const data = {
          payment_method: {
            card: elements?.getElement(CardElement) as any,
            billing_details: values.billingDetails,
            metadata: {
              type: "PerGoal",
              donateInviteId: res.data.data.donateInviteId,
            },
          },
        };
        const result = await stripe.confirmCardSetup(res.data.data.clientSecret, data);
        donateInviteStore.saving = false;

        if (result.error) {
          console.log(result.error.message);
          donateInviteStore.setStste("Error");

          donateInviteStore.setMsg("Cannot process your payment. Please check the information or try another card.");
        } else {
          if (result?.setupIntent?.status === "succeeded") {
            // Show a success message to your customer
            // There's a risk of the customer closing the window before callback
            // execution. Set up a webhook or plugin to listen for the
            // payment_intent.succeeded event that handles any business critical
            // post-payment actions.
            // showSuccess();
            donateInviteStore.setStste("Success");
            // donateStore.close();
            // setTimeout(() => {
            //   parent.loadParent();
            // }, 3000);
          }
        }
      } else {
        const res = await Api.donateInvite.paySecret(id, values.amount);

        const data = {
          payment_method: { card: elements?.getElement(CardElement) as any, billing_details: values.billingDetails },
        };
        const result = await stripe.confirmCardPayment(res.data.data.clientSecret, data);
        donateInviteStore.saving = false;

        if (result.error) {
          console.log(result.error.message);
          donateInviteStore.setStste("Error");

          donateInviteStore.setMsg("Cannot process your payment. Please check the information or try another card.");
        } else {
          if (result?.paymentIntent?.status === "succeeded") {
            // Show a success message to your customer
            // There's a risk of the customer closing the window before callback
            // execution. Set up a webhook or plugin to listen for the
            // payment_intent.succeeded event that handles any business critical
            // post-payment actions.
            // showSuccess();
            donateInviteStore.setStste("Success");
            // donateStore.close();
            // setTimeout(() => {
            //   parent.loadParent();
            // }, 3000);
          }
        }
      }
    });
  };

  const onChange = (e) => {
    console.log("onChange", e);
    donateInviteStore.setMsg("");
  };

  return (
    <div className="donate-form">
      <div className="logo"></div>

      {loading && <Spin size="large" />}
      {!loading && !inviteInfo && (
        <Card>
          <Result status="error" title="Invalid Invite Information" />
        </Card>
      )}

      {inviteInfo && (
        <>
          <div className="title">
            {inviteInfo.orgName}'s {inviteInfo.fundName}
          </div>
          {/* <div className="title">Reading Challenge</div> */}

          <div className="invitation">Invitation from: {inviteInfo.userName}</div>

          {inviteInfo?.perGoal && inviteInfo?.complete && (
            <Alert
              banner={true}
              message={`${inviteInfo.fundName} has Ended`}
              description={`But ${inviteInfo.orgName} would still appreciate your support if you can.`}
              type="warning"
            />
          )}

          <Card className={donateInviteStore.state === "Error" ? "error" : ""}>
            {donateInviteStore.state === "Pending" && (
              <Form layout="vertical" form={form}>
                {inviteInfo.perGoal && (
                  <Form.Item label="Name of Child You Are Sponsoring">
                    <Input size="large" readOnly value={inviteInfo.perGoal.childNames}></Input>
                  </Form.Item>
                )}
                {inviteInfo.perGoal ? (
                  <Form.Item
                    label={`Donation/${inviteInfo.perGoal.singular}`}
                    name={["perGoal", "amount"]}
                    rules={[{ required: true, message: "Please input donate amount" }]}
                    extra={
                      inviteInfo.perGoal.minimum &&
                      `Subject to ${fmtMoney(inviteInfo.perGoal.minimum)} minimum to reward child participation`
                    }
                  >
                    <InputNumber min={0} size="large" prefix="$"></InputNumber>
                  </Form.Item>
                ) : (
                  <Form.Item
                    name="amount"
                    rules={[
                      { required: true, message: "Please input donate amount" },
                      { type: "number", min: 1, message: "Donation amount cannot be less than $1.00." },
                    ]}
                    style={{ textAlign: "center" }}
                  >
                    <MoneyInput
                      className="donate-input"
                      value={donateInviteStore.amount}
                      max={9999.99}
                      onChange={(value) => (donateInviteStore.amount = Number(value))}
                      style={{ color: "#191a1d", fontSize: "28px", fontWeight: 600, textAlign: "center" }}
                    ></MoneyInput>
                  </Form.Item>
                )}
                <Form.Item
                  label="Card Holder Name"
                  name={["billingDetails", "name"]}
                  rules={[{ required: true, message: "Please input your name" }]}
                >
                  <Input size="large"></Input>
                </Form.Item>
                <Form.Item
                  label="Credit or Debit Card"
                  required
                  help={donateInviteStore.msg}
                  validateStatus={donateInviteStore.msg ? "error" : "success"}
                >
                  <CardSection onChange={onChange} />
                </Form.Item>
                {inviteInfo.perGoal && (
                  <>
                    <Form.Item label="Max Donation per Child (optional)" name={["perGoal", "maxLimit"]}>
                      <InputNumber size="large" prefix="$"></InputNumber>
                    </Form.Item>
                    <p>Would you like to hear the result from us?</p>
                    <Form.Item label="Email" name={["perGoal", "email"]}>
                      <Input size="large"></Input>
                    </Form.Item>
                    <Form.Item label="Mobile Number" name={["perGoal", "mobile"]}>
                      <Input size="large"></Input>
                    </Form.Item>
                  </>
                )}

                <Form.Item style={{ textAlign: "center" }}>
                  <Button
                    size="large"
                    block
                    type="primary"
                    className="btn"
                    onClick={onDonate}
                    loading={donateInviteStore.saving}
                  >
                    Donate
                  </Button>
                </Form.Item>
              </Form>
            )}
            {donateInviteStore.state === "Success" && (
              <Result status="success" title="Success!" subTitle={`Thank you for supporting ${inviteInfo.orgName}`} />
            )}
            {donateInviteStore.state === "Error" && (
              <Result
                status="warning"
                title="Sorry, we were unable to process your donation"
                subTitle="Please check your imformation and try again."
                extra={
                  <Button type="primary" onClick={() => donateInviteStore.retry()}>
                    Try Again
                  </Button>
                }
              />
            )}
          </Card>
        </>
      )}
    </div>
  );
});
