import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { appStore } from "../../../../store/AppStore";
import { Form, Input, Modal, Select, Button, Typography } from "antd";
import NumberFormat from "react-number-format";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 14 },
};
const tailLayout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
};
export const InviteDonateDlg = observer(() => {
  const [form] = Form.useForm();
  const [type, setType] = useState("Email");
  const { inviteDonateStore } = appStore.parent;
  inviteDonateStore.form1 = form;

  const onInvite = () => {
    form.validateFields().then((values) => {
      inviteDonateStore.sendInvite(values);
    });
  };

  return (
    <Modal
      footer={null}
      title="Invite Family & Friends"
      visible={inviteDonateStore.visible}
      onCancel={() => inviteDonateStore.close()}
    >
      <Form form={form} initialValues={{ inviteType: "Email" }} {...layout}>
        <Typography.Paragraph style={{ padding: "0 6px", marginBottom: 48 }}>
          We will send an email or text to people you invite for donation. We do NOT sell their info or your info. At
          the midway point of the fundraiser, a reminder will be sent only to those who have not donated. And we send a
          thank you note for each donation. Other than these communications, we will not contact them.
        </Typography.Paragraph>

        <Form.Item label="First/Nick Name" name="inviteName" rules={[{ required: true, message: "Please input name" }]}>
          <Input></Input>
        </Form.Item>
        <Form.Item label="Contact Method" name="inviteType" rules={[{ required: true }]}>
          <Select
            onChange={(e: string) => {
              setType(e);
              form.setFieldsValue({ inviteData: "" });
            }}
          >
            <Select.Option value="Email">Email</Select.Option>
            <Select.Option value="Mobile">Mobile</Select.Option>
          </Select>
        </Form.Item>
        {type === "Email" && (
          <Form.Item
            label="Email"
            name="inviteData"
            rules={[
              { required: true, message: "Please input email" },
              { type: "email", message: "Please input valid email" },
            ]}
          >
            <Input></Input>
          </Form.Item>
        )}
        {type === "Mobile" && (
          <Form.Item label="Mobile" name="inviteData" rules={[{ required: true, message: "Please input mobile" }]}>
            <NumberFormat format="+1 (###) ###-####" mask="_" className="ant-input" />
          </Form.Item>
        )}
        <Form.Item style={{ textAlign: "center" }} {...tailLayout}>
          <Button type="primary" shape="round" size="large" onClick={onInvite} loading={inviteDonateStore.saving}>
            Invite
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
});
