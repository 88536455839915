import React from "react";
import { Dropdown, Menu } from "antd";
import { observer } from "mobx-react";
import { QuestionCircleOutlined, CaretDownOutlined } from "@ant-design/icons";

type SelectCardHeader = {
  items: any[];
  curId: string;
  onClick: (id: string) => void;
  center?: boolean;
};

export const SelectCardHeader = observer(({ items = [], curId, onClick, center = false }: SelectCardHeader) => {
  const menu = (
    <Menu>
      {items.map((x: any) => (
        <Menu.Item key={x.id} onClick={() => onClick && onClick(x.id)}>
          {x.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <div
        className="FrCardHeader FrCardSelectHeader"
        style={{
          justifyContent: center ? "center" : "flex-start",
          cursor: "pointer",
        }}
      >
        <div className="title">{items.find((x: any) => x.id === curId)?.name}</div>
        {items.length > 1 && <CaretDownOutlined className="select" />}
      </div>
    </Dropdown>
  );
});
