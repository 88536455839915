import React, { useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { MainPage } from "./pages/Main";
import * as serviceWorker from "./serviceWorker";
import "./common/styles.scss";
import { Router, Route, Switch } from "react-router";
import { observer } from "mobx-react-lite";
import { myHistory } from "./common/History";
import { ApolloProvider } from "@apollo/react-hooks";
import "./common/Aws";
import Auth from "@aws-amplify/auth";
import { client } from "./graphql/client";
import { App } from "./pages/App";
import { LoginPage } from "./pages/auth/LoginPage";
import { appStore } from "./store/AppStore";
import { InvitePage } from "./pages/auth/Invite";
import { DonatePage } from "./pages/other/Donate";
import "./common/mobile.scss";
// import { PubHome } from "./pages/pub/Home";
import { RegPage } from "./pages/auth/RegPage";

Auth.currentAuthenticatedUser({
  bypassCache: true,
})
  .then((user) => {
    console.log("here", user);
    Auth.userAttributes(user)
      .then((res) => {})
      .catch((err) => {
        console.log("dd", err);
        if (err.code === '"PasswordResetRequiredException"') {
          myHistory.push("/reset");
        }
      });
  })
  .catch((err) => {
    const { pathname = "" } = myHistory.location;
    console.log("ddddd", pathname);
    if (
      !pathname.startsWith("/invite/") &&
      !pathname.startsWith("/donate/") &&
      !pathname.startsWith("/d/") &&
      !["/login", "/contact", "/how", "/about"].includes(pathname)
    ) {
      myHistory.push("/");
    }
  });

const MyRouter = observer(() => {
  return (
    <ApolloProvider client={client}>
      <Router history={myHistory}>
        <Switch>
          <Route path="/login" exact component={LoginPage} />
          <Route path="/reg" exact component={RegPage} />
          <Route path="/invite/:id" exact component={InvitePage} />
          <Route path="/donate/:id" exact component={DonatePage} />
          <Route path="/d/:id" exact component={DonatePage} />
          <Route path="/" component={appStore.isLogin ? App : RegPage} />
        </Switch>
      </Router>
    </ApolloProvider>
  );
});

ReactDOM.render(<MyRouter />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
