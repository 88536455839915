import { ApolloError } from "apollo-client";
import { makeAutoObservable, action, computed, runInAction } from "mobx";
import { Api } from "../common/Api";
import { showError, showSuccess } from "../common/Utils";
import { AppStore } from "./AppStore";
import Auth from "@aws-amplify/auth";
import { FormInstance } from "antd/lib/form";

export class ChangeEmailStore {
  store: AppStore;
  form?: FormInstance;

  email: string = "";
  code: string = "";
  pwd: string = "";
  isCountDown = false;
  isResend = false;
  saving = false;
  errMsg = "";

  constructor(store: AppStore) {
    makeAutoObservable(this, {
      store: false,
      curEmail: computed,
      canSendCode: computed,
      canSubmit: computed,
    });
    this.store = store;
  }

  get curEmail() {
    return this.store.me.email;
  }

  get canSendCode() {
    return !!this.email.trim();
  }

  get canSubmit() {
    return !!this.email.trim() && !!this.code.trim() && !!this.pwd.trim();
  }

  @action sendCode() {
    if (!this.canSendCode) return;

    this.isCountDown = true;
    Api.sendEmailCode(this.email).then((res) => {
      // console.log("res", res);
    });
  }

  @action countDownFinish() {
    this.isCountDown = false;
    this.isResend = true;
  }

  @action submit() {
    if (!this.canSubmit) return;

    this.saving = true;
    Auth.signIn(this.curEmail, this.pwd)
      .then((user) => {
        return Api.updateEmail(this.email, this.code)
          .then((res) => {
            runInAction(() => {
              this.store.me.email = this.email;
              this.email = "";
              this.code = "";
              this.pwd = "";
              this.isCountDown = false;
              this.isResend = false;
            });
            this.form?.resetFields();
            showSuccess();
          })
          .catch((err: ApolloError) => {
            if (err.graphQLErrors) {
              console.log("err", err.graphQLErrors?.[0]?.message);
              let msg = err.graphQLErrors?.[0]?.message;
              if (msg && msg.split(":").length > 1) {
                msg = msg.split(":")[1];
                msg = msg.replaceAll(`"`, "");
              }
              if (msg === "Incorrect verification code.") {
                showError("Incorrect information. Please check you password or confirmation code.");
              } else {
                showError(msg);
              }
            }
          });
      })
      .catch((err) => {
        showError("Incorrect information. Please check you password or confirmation code.");
      })
      .finally(() => (this.saving = false));
  }
}
