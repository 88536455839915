import { makeAutoObservable, computed, action } from "mobx";
import { Api } from "../../common/Api";
import { fundraiser } from "../domains/Fundraiser";
import { AdminStore } from "./AdminStore";
import { FormInstance } from "antd/lib/form";

export class EditFundraisingStore {
  store: AdminStore;
  visible = false;
  saving = false;
  form?: FormInstance;

  model = {
    orgId: null,
    type: 0,
    suggestions: [{ label: undefined, amount: undefined }],
  };

  constructor(store: AdminStore) {
    makeAutoObservable(this, {
      store: false,
      organizers: computed,
      school: computed,
    });
    this.store = store;
  }

  get school() {
    return fundraiser.schools.find((x) => x.id === this.model.orgId);
  }
  get organizers() {
    return this.school?.frOrganizers || [];
  }

  @action
  onSchoolChange(orgId) {
    this.model.orgId = orgId;
    // this.school?.loadParticipants();
    // this.participant = null;
    this.form!.setFieldsValue({ funOrgId: undefined });
  }
  @action onTypeChange(type) {
    this.model.type = type;
  }

  show() {
    this.model.orgId = this.store.schoolsStore.activeSchoolId;
    this.model.type = 0;
    this.visible = true;
  }

  save(input) {
    this.saving = true;
    return Api.fund
      .saveFundraiser({
        ...input,
        orgId: this.model.orgId,
      })
      .then(async (res) => {
        this.visible = false;
        await this.store.schoolsStore.school?.loadAdminFundraisings();
        // showSuccess();
      })
      .finally(() => (this.saving = false));
  }

  close() {
    this.visible = false;
  }
}
