import { gql } from "apollo-boost";

const adminFundraisings = gql`
  query adminFundraisings($orgId: String!) {
    adminFundraisings(orgId: $orgId) {
      id
      type
      name
      start
      end
      proceeds
      suggested
      target
      description
      count
      reached
      status
      state
      shareCode
      familyCount
      totalParticipated
      reachedStudent
      totalStudents
      goalCompletedCount
      perGoal {
        singular
        plural
        description
        minimum
      }
      organizer {
        id
        name
      }
      top10Families {
        total
        meta {
          userName
          children
        }
      }
    }
  }
`;

export const FrAdmin = gql`
  {
    admin {
      organizers {
        id
        orgId
        name
        nickName
      }
      fundraisers {
        id
        type
        name
        start
        end
        shareCode
        proceeds
        suggested
        target
        description
        count
        reached
        familyCount
        perGoal {
          singular
          plural
          description
          minimum
        }
        organizer {
          id
          name
        }
        top10Families {
          total
          meta {
            userName
            children
          }
        }
      }
    }
  }
`;

const SaveFundraiser = gql`
  mutation saveFundraiser($input: FrFundraiserInput!) {
    saveFundraiser(input: $input)
  }
`;

const delFundraiser = gql`
  mutation delFundraiser($id: String!) {
    delFundraiser(id: $id)
  }
`;

export const AdminGql = { adminFundraisings, SaveFundraiser, delFundraiser };
