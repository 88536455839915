import { FormInstance } from "antd/lib/form";
import { action, makeAutoObservable, runInAction } from "mobx";
import { Api } from "../../../common/Api";
import { showSuccess } from "../../../common/Utils";
import { parent } from "../../domains/Parent";
import { ParentStore } from "../ParentStore";

export class InviteDonateStore {
  form1?: FormInstance;
  form2?: FormInstance;

  store: ParentStore;

  visible = false;
  showRemide = false;
  saving = false;
  model;

  constructor(store: ParentStore) {
    makeAutoObservable(this, {
      store: false,
    });
    this.store = store;
  }

  @action show() {
    this.visible = true;
    this.form1?.resetFields();
  }

  @action edit(model) {
    this.model = model;
    this.showRemide = true;
    this.form2?.setFieldsValue(model);
  }

  @action close() {
    this.visible = false;
    this.showRemide = false;
  }

  @action sendInvite(data) {
    this.saving = true;
    return Api.parent
      .sendInviteDonate({ fundId: this.store.fundraisingsStore.activeFundId, ...data })
      .then((res) => {
        runInAction(async () => {
          showSuccess();
          this.visible = false;
          parent.loadParent();
        });
      })
      .finally(() => (this.saving = false));
  }

  @action sendRemide(data) {
    this.saving = true;
    return Api.parent
      .sendInviteDonate({ id: this.model.id, fundId: this.store.fundraisingsStore.activeFundId, ...data })
      .then((res) => {
        runInAction(async () => {
          showSuccess();
          this.showRemide = false;
          parent.loadParent();
        });
      })
      .finally(() => (this.saving = false));
  }
}
