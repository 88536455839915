import React, { useState } from "react";
import { Input } from "antd";
import { CheckOutlined, PlusCircleOutlined } from "@ant-design/icons";
import "./SelectList.scss";

interface SelectListProps {
  value?: { checked: boolean; value: string }[];
  onChange?: (val: any) => void;
  radio?: boolean;
}

export const SelectList = ({ value = [], onChange, radio = false }: SelectListProps) => {
  const [text, setText] = useState("");

  const onSelect = (i) => {
    if (radio) {
      value.forEach((x, ii) => {
        x.checked = i == ii;
      });
    } else {
      value[i].checked = !value[i].checked;
    }
    onChange && onChange([...value]);
  };

  const onAdd = () => {
    if (!text.trim()) return;
    if (radio) {
      value.forEach((x) => {
        x.checked = false;
        return x;
      });
      onChange && onChange([...value, { checked: true, value: text.trim() }]);
    } else {
      onChange && onChange([...value, { checked: true, value: text.trim() }]);
    }
    setText("");
  };

  const onEdit = (i, val) => {
    value[i].value = val;
    onChange && onChange([...value]);
  };

  return (
    <div className="select-list">
      {value.map((it, i) => (
        <div key={i} className={`select-list-item ${it.checked ? "active" : ""}`} onClick={() => onSelect(i)}>
          <Input.TextArea
            autoSize
            value={it.value}
            onChange={(e) => onEdit(i, e.target.value)}
            style={{ border: "none" }}
          />
        </div>
      ))}
      <Input
        placeholder="Add New"
        value={text}
        onChange={(e) => setText(e.target.value)}
        onPressEnter={onAdd}
        suffix={<PlusCircleOutlined onClick={onAdd} style={{ color: "#0CBEFF" }} />}
      />
    </div>
  );
};
