import { gql } from "apollo-boost";
import { fragment } from "./fragment";

export const parent = gql`
  {
    parent {
      ...parentInfo
    }
  }
  ${fragment.parentInfo}
`;

export const updateFundGoals = gql`
  mutation updateFundGoals($id: String!, $goals: [FrFundraiserStudentGoalInput!]!) {
    updateFundGoals(id: $id, goals: $goals)
  }
`;

export const inviteDonate = gql`
  mutation inviteDonate($input: FrDonateInviteInput!) {
    inviteDonate(input: $input)
  }
`;

export const parentGql = {
  parent,
  updateFundGoals,
  inviteDonate
};
