import axios from "axios";
import Auth from "@aws-amplify/auth";
import { Aws } from "./Aws";
import { notification } from "antd";
import { client } from "../graphql/client";
import { meGql } from "../graphql/me";
import { teachGql } from "../graphql/teacher";
import { AdminGql } from "../graphql/admin";
import { engGql } from "../graphql/engagement";
import { parentGql } from "../graphql/parent";

axios.defaults.baseURL = process.env.REACT_APP_API;

axios.interceptors.request.use(
  async (config) => {
    let token = "";
    try {
      token = (await Auth.currentSession()).getIdToken().getJwtToken();
    } catch (err) {
      console.log("token", err);
    }

    if (token) {
      if (process.env.NODE_ENV === "production") {
        config.headers = {
          Authorization: token,
        };
      } else {
        config.headers = {
          Authorization: token,
          // "x-fm-uid": await Aws.getUserId(),
        };
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (res) => res,
  (error) => {
    if (error.response && error.response.data)
      notification.error({ message: error.response.data.message || "Service Error." });
    return Promise.reject(error);
  }
);

export const Api = {
  me: () => client.query({ query: meGql.me, fetchPolicy: "network-only" }),
  sendEmailCode: (email) => client.mutate({ mutation: meGql.sendEmailCode, variables: { email } }),
  updateEmail: (email, code) => client.mutate({ mutation: meGql.updateEmail, variables: { email, code } }),

  searchSchools: (keyword) =>
    client.query({ query: meGql.searchSchools, variables: { filter: { keyword } }, fetchPolicy: "network-only" }),
  invite: {
    findInviteEmail: (id) => axios.get(`${process.env.REACT_APP_ADMIN_API}/invite?id=${id}`),
    inviteInitPwd: (id, pwd) => axios.post(`${process.env.REACT_APP_ADMIN_API}/invite`, { id, pwd }),
  },
  donateInvite: {
    get: (id) => axios.get(`${process.env.REACT_APP_ADMIN_API}/donate?id=${id}`),
    paySecret: (id, amount) =>
      axios.post(`${process.env.REACT_APP_ADMIN_API}/donate/createInviteDonatePaySecret`, { id, amount }),
    perGoal: (input) => axios.post(`${process.env.REACT_APP_ADMIN_API}/donate/donateInvitePerGoal`, input),
  },

  parent: {
    parent: () => client.query({ query: parentGql.parent, fetchPolicy: "network-only" }),
    payMethods: () => client.query({ query: meGql.payMethods, fetchPolicy: "network-only" }),
    saveChild: (input) => client.mutate({ mutation: meGql.saveChild, variables: { input } }),
    delChild: (childId) => client.mutate({ mutation: meGql.delChild, variables: { childId } }),
    updateFundGoals: (id, goals) => client.mutate({ mutation: parentGql.updateFundGoals, variables: { id, goals } }),

    sendInviteDonate: (input) => client.mutate({ mutation: parentGql.inviteDonate, variables: { input } }),
  },

  teacher: {
    events: (orgId) => client.query({ query: teachGql.teachEvents, variables: { orgId }, fetchPolicy: "network-only" }),
    classes: (orgId) =>
      client.query({ query: teachGql.teachClasses, variables: { orgId }, fetchPolicy: "network-only" }),
    students: (filter) =>
      client.query({ query: teachGql.students, variables: { filter }, fetchPolicy: "network-only" }),
    engParticipants: (orgId) =>
      client.query({ query: teachGql.engParticipants, variables: { orgId }, fetchPolicy: "network-only" }),
    saveClass: (input) => client.mutate({ mutation: teachGql.saveClass, variables: { input } }),
    delClass: (classId) => client.mutate({ mutation: teachGql.delClass, variables: { classId } }),

    engTool: () => client.query({ query: engGql.engTool }),
    engActivity: (id) => client.query({ query: engGql.engActivity, variables: { id }, fetchPolicy: "network-only" }),
    saveEngActivity: (input) => client.mutate({ mutation: engGql.saveEngActivity, variables: { input } }),
    completeEngActivity: (id) => client.mutate({ mutation: engGql.completeEngActivity, variables: { id } }),
    remindEngActivity: (id, status) => client.mutate({ mutation: engGql.remindEngActivity, variables: { id, status } }),
  },

  fund: {
    adminFundraisings: (orgId) =>
      client.query({ query: AdminGql.adminFundraisings, variables: { orgId }, fetchPolicy: "network-only" }),
    saveFundraiser: (input) => client.mutate({ mutation: AdminGql.SaveFundraiser, variables: { input } }),
    delFundraiser: (id) => client.mutate({ mutation: AdminGql.delFundraiser, variables: { id } }),
  },

  post: {
    get: (id) => axios.get(`/community/post/${id}`),
    challenge: (id) => axios.get(`/community/challenge/${id}`),
    sendComment: (id, comment) => axios.post(`/community/post/${id}/comment`, { comment }),
  },

  reg: {
    emailCode: (email) => axios.post(`${process.env.REACT_APP_ADMIN_API}/reg/emailCode`, { email }),
    schools: (keyword) => axios.post(`${process.env.REACT_APP_ADMIN_API}/reg/schools`, { keyword }),
    reg: (input) => axios.post(`${process.env.REACT_APP_ADMIN_API}/reg`, input),
  },

  contactUs: (data) => axios.post(`${process.env.REACT_APP_ADMIN_API}/contact-us`, data),
};
