import React from "react";
import { Layout } from "antd";
import { ParentSchoolsBar } from "../components/ParentSchoolsBar";
import { observer } from "mobx-react";
import { ParentFundraiser } from "./ParentFundraiser";

export const ParentFundraisers = observer(() => {
  return (
    <Layout className="home">
      <Layout.Header className="headerWrap">
        <ParentSchoolsBar
          btnText="New Event"
          onBtn={() => {
            // setShowDlg(true);
          }}
        />
      </Layout.Header>
      <Layout.Content>
        <div className="container body-wrapper">
          <ParentFundraiser></ParentFundraiser>
        </div>
      </Layout.Content>
    </Layout>
  );
});
