import { List, Typography } from "antd";
import { observer } from "mobx-react";
import React, { CSSProperties, FC } from "react";
import { FrCard } from "../../../../components/FrCard";
import { appStore } from "../../../../store/AppStore";
import { InviteDonateBtn } from "./InviteDonateBtn";
import { InviteDonateDlg } from "./InviteDonateDlg";
import "./FriendsDonation.scss";
import { FormOutlined } from "@ant-design/icons";
import { fmtMoney } from "../../../../common/Utils";
import { RemindDonateDlg } from "./RemindDonateDlg";

export const FriendsDonation: FC<{ style?: CSSProperties }> = observer(({ style }) => {
  const { activeFund } = appStore.parent.fundraisingsStore;
  const { inviteDonateStore } = appStore.parent;

  return (
    <FrCard
      style={style}
      bodyStyle={{ height: "100%" }}
      title="Family & Friends Donation"
      tip="We will send an email or text to people you invite for donation. We do NOT sell their info or your info. At the midway point of the fundraiser, a reminder will be sent only to those who have not donated. And we send a thank you note for each donation. Other than these communications, we will not contact them for this fundraiser."
    >
      {activeFund.familyDonates.length === 0 && (
        <div className="no-donate-wrap">
          <InviteDonateBtn></InviteDonateBtn>
        </div>
      )}
      {activeFund.familyDonates.length > 0 && (
        <div>
          <List
            className="friends-donate"
            split={false}
            footer={
              <div style={{ textAlign: "center" }}>
                <InviteDonateBtn></InviteDonateBtn>
              </div>
            }
            dataSource={activeFund.familyDonates}
            renderItem={(item: any) => (
              <List.Item>
                <List.Item.Meta
                  title={
                    <span style={{ marginLeft: 16 }} className="title">
                      {item.inviteName}{" "}
                      <FormOutlined
                        onClick={() => inviteDonateStore.edit(item)}
                        style={{ marginLeft: 12, color: "#828587" }}
                      />
                    </span>
                  }
                />
                {item.amount > 0 && <div className="status">{fmtMoney(item.amount / 100.0, 0)}</div>}
                {item.amount == 0 && item.remind && <div className="status">Reminder sent</div>}
                {item.amount == 0 && !item.remind && <div className="status">Invite sent</div>}
              </List.Item>
            )}
          />
        </div>
      )}
      <InviteDonateDlg></InviteDonateDlg>
      <RemindDonateDlg></RemindDonateDlg>
    </FrCard>
  );
});
