import React from "react";
import { useMutation } from "@apollo/react-hooks";
import { engGql } from "../../../graphql/engagement";
import { NavLink } from "react-router-dom";
import { Layout, Tabs, Table, Card, Space, Button, Modal } from "antd";
import { TeachSchoolsBar } from "../components/TeachSchoolsBar";
import { showSuccess, timeDuration } from "../../../common/Utils";
import { DeleteOutlined } from "@ant-design/icons";
import { appStore } from "../../../store/AppStore";
import { observer } from "mobx-react";

export const Events = observer(() => {
  const [delEngActivity, { loading: saving }] = useMutation(engGql.delEngActivity);

  const preDel = (id) => {
    Modal.confirm({
      content: "Confirm deletion.",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      icon: null,
      centered: true,
      onOk: () => {
        console.log("del");
        delEngActivity({ variables: { id } }).then(() => {
          showSuccess();
          // refetch();
        });
      },
    });
  };

  const columns = [
    {
      title: <span style={{ paddingLeft: 24 }}>Challenge Name</span>,
      key: "name",
      dataIndex: "name",
      render: (_, r) => <span style={{ paddingLeft: 24 }}>{r.title || r.customName || r.name}</span>,
    },
    { title: "Share code", key: "shareCode", dataIndex: ["shareCode"] },
    { title: "Participants", key: "participants", dataIndex: ["participant", "title"] },
    { title: "Time Duration", key: "time", render: (_, r) => timeDuration(r) },
    {
      title: "",
      key: "opt",
      width: 120,
      render: (_, r) => (
        <Space>
          <NavLink to={`./events/${r.id}`}>Detail</NavLink>
        </Space>
      ),
    },
  ];

  const columns2 = [
    {
      title: <span style={{ paddingLeft: 24 }}>Challenge Name</span>,
      key: "name",
      dataIndex: "name",
      render: (_, r) => <span style={{ paddingLeft: 24 }}>{r.title || r.customName || r.name}</span>,
    },
    { title: "Participants", key: "participants", dataIndex: ["participant", "title"] },
    { title: "Time Duration", key: "time", render: (_, r) => timeDuration(r) },
    {
      title: "",
      key: "opt",
      width: 120,
      render: (_, r) => (
        <Space>
          <NavLink to={`./events/${r.id}`}>Detail</NavLink>
          <Button icon={<DeleteOutlined />} type="link" danger onClick={() => preDel(r.id)}></Button>
        </Space>
      ),
    },
  ];

  const { inProgress, scheduled, history } = appStore.teach.schoolsStore;
  console.log(inProgress, scheduled, history);
  return (
    <Layout className="challenge-events">
      <Layout.Header className="headerWrap" style={{ position: "fixed", zIndex: 1000, width: "100%" }}>
        <TeachSchoolsBar
          btnText="New Event"
          onBtn={() => {
            appStore.teach.challenge.show();
          }}
        />
      </Layout.Header>
      <Layout.Content style={{ marginTop: 64 }}>
        <div className="container body-wrapper">
          <Card style={{ marginTop: 20 }} bodyStyle={{ padding: 0 }}>
            <Tabs defaultActiveKey="0" style={{ minHeight: 300 }}>
              <Tabs.TabPane tab="In Progress" key="0">
                <Table
                  rowKey="id"
                  scroll={{ x: true }}
                  columns={columns}
                  dataSource={inProgress}
                  pagination={false}
                ></Table>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Scheduled" key="1">
                <Table
                  rowKey="id"
                  scroll={{ x: true }}
                  columns={columns2}
                  dataSource={scheduled}
                  pagination={false}
                ></Table>
              </Tabs.TabPane>
              <Tabs.TabPane tab="History" key="2">
                <Table
                  rowKey="id"
                  scroll={{ x: true }}
                  columns={columns}
                  dataSource={history}
                  pagination={false}
                ></Table>
              </Tabs.TabPane>
            </Tabs>
          </Card>
        </div>
      </Layout.Content>
    </Layout>
  );
});
