import React from "react";
import "./Account.scss";
import { FrCard } from "../../components/FrCard";
import { fmtDate, fmtMoney } from "../../common/Utils";
import { observer } from "mobx-react";
import { parent } from "../../store/domains/Parent";

export const Donates = observer(() => {
  return (
    <FrCard title="Donation History ">
      <table className="students">
        <tbody>
          {parent.donates.map((s: any, i) => (
            <tr key={s.id}>
              <td>{fmtDate(s.createDate)}</td>
              <td>{s.fundraiser?.organizer?.name}</td>
              <td>{s.fundraiser?.name}</td>
              <td>
                <div style={{ paddingRight: 16 }}>{fmtMoney(s.amount / 100.0)}</div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </FrCard>
  );
});
