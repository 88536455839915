import React, { FC } from "react";

export const ImgCard: FC<{ bg; width?: any; height?: any; style?: any; onClick?: any }> = ({
  bg,
  width,
  height,
  children,
  onClick,
  style = {},
}) => {
  return (
    <div
      className={`img-card ${bg}`}
      style={{ width, height, ...style }}
      onClick={() => {
        onClick && onClick();
      }}
    >
      {children}
    </div>
  );
};
