import React, { useEffect } from "react";
import { Modal, Input, Form, DatePicker, Steps, Select, Radio, Typography, Button } from "antd";
import { FinishPanel } from "./FinishPanel";
import { ParentPreview } from "./ParentPreview";
import moment from "moment";
import "./engagement.scss";
import { SelectList } from "./SelectList";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Utils, defaultLayout } from "../../../../common/Utils";
import { StepBtns } from "./StepBtns";
import { observer } from "mobx-react";
import { appStore } from "../../../../store/AppStore";
import { teacher } from "../../../../store/domains/Teacher";

const { RangePicker } = DatePicker;
const { Step } = Steps;
const dateFormat = "YYYY/MM/DD";

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 16, offset: 6 },
  },
};

export const ChallengeDlg = observer(() => {
  const [form] = Form.useForm();
  const store = appStore.teach.challenge;
  store.form = form;

  useEffect(() => {
    form.resetFields();
  }, [store.visible]);

  const onValuesChange = (values) => {
    if (Utils.hasKey(values, "participantId")) {
      store.onParticipantChange(values.participantId);
    } else if (Utils.hasKey(values, "orgId")) {
      store.onSchoolChange(values.orgId);
    } else if (Utils.hasKey(values, "type")) {
      store.onTypeChange(values.type);
    } else if (Utils.hasKey(values, "name")) {
      store.onNameChange(values.name);
    } else if (Utils.hasKey(values, "range")) {
      store.model.range = values.range;
    } else if (Utils.hasKey(values, "customName")) {
      store.model.customName = values.customName;
    } else if (Utils.hasKey(values, "addName")) {
      store.onAddNameChange(values.addName);
    } else if (Utils.hasKey(values, "workSubmission") && Utils.hasKey(values.workSubmission, "enabled")) {
      store.isWork = values.workSubmission.enabled;
    }
  };

  const disabledDate = (current) => {
    return current && current < moment().startOf("day");
  };

  const { challenge } = appStore.teach;
  return (
    <Modal
      visible={challenge.visible}
      maskClosable={false}
      destroyOnClose={true}
      confirmLoading={store.saving}
      onCancel={() => appStore.teach.challenge.close()}
      width={1196}
      getContainer={false}
      footer={false}
      className="engagementDlg"
    >
      <Steps current={store.curStep} labelPlacement="vertical" responsive>
        <Step title="Participants, Time & Activity" />
        <Step title="Challenge Goals" />
        <Step title="Work Submission" />
        <Step title="How Parents See This" />
        <Step title="Finish" />
      </Steps>
      <Form form={form} {...defaultLayout} colon={false} initialValues={store.model} onValuesChange={onValuesChange}>
        {store.curStep === 0 && (
          <div>
            {teacher.schools.length > 1 && (
              <Form.Item
                label="Choose School"
                name="orgId"
                rules={[{ required: true, message: "Please select a school." }]}
              >
                <Select>
                  {teacher.schools.map((x: any) => (
                    <Select.Option value={x.id} key={x.id}>
                      {x.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <Form.Item
              label="Choose Participants"
              name="participantId"
              rules={[{ required: true, message: "Please select a group." }]}
              extra={
                <div className="fcc" style={{ justifyContent: "space-between" }}>
                  <div>
                    {store.participant
                      ? `${store.participant.count} ${store.participant.count > 1 ? "students" : "student"}  selected.`
                      : "Please select a group."}
                  </div>
                  <Button
                    type="link"
                    icon={<PlusCircleOutlined />}
                    onClick={() => appStore.teach.schoolsStore.editGroup.show(true, store.model.orgId)}
                  >
                    Create a Student Group
                  </Button>
                </div>
              }
            >
              <Select>
                {store.participants.map((x: any) => (
                  <Select.Option value={x.type + x.typeId} key={x.type + x.typeId}>
                    {x.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Time Duration"
              name="range"
              rules={[{ required: true, message: "Please input Time Duration." }]}
            >
              <RangePicker format={dateFormat} style={{ width: "100%" }} disabledDate={disabledDate} />
            </Form.Item>
            <Form.Item label="Activity Type" name="type">
              <Select>
                {store.engTool.activityTypes.map((x: any) => (
                  <Select.Option value={x.data} key={x.id}>
                    {x.data}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Activity Name" name="name">
              <Select>
                {store.toolActivities.map((x: any) => (
                  <Select.Option value={x.activityName} key={x.id}>
                    {store.addGroupName}
                    {x.activityName}
                  </Select.Option>
                ))}
                <Select.Option value={"-"}>Design Your Own Challenge</Select.Option>
              </Select>
            </Form.Item>

            {store.isCustomName ? (
              <Form.Item
                label="Create a Name"
                name="customName"
                rules={[{ required: true, message: "Please input name." }]}
              >
                <Input maxLength={60} placeholder="Enter name" />
              </Form.Item>
            ) : (
              <Form.Item label=" " name="addName">
                <Radio.Group>
                  <Radio value={"None"}>None</Radio>
                  <Radio value={"Group"}>Add Group Name</Radio>
                </Radio.Group>
              </Form.Item>
            )}
            <Form.Item
              label="Invitation to Parents "
              name="description"
              rules={[{ required: true, message: "Please input description." }]}
            >
              <Input.TextArea
                autoSize
                value={store.model.description}
                onChange={(e) => (store.model.description = e.target.value)}
                style={{ minHeight: 100 }}
                placeholder="This will be part of the challenge description for students and parents."
              />
            </Form.Item>
          </div>
        )}
        {store.curStep === 1 && (
          <>
            <Form.Item
              required
              label="Set Goals"
              name={["goal", "enabled"]}
              extra={
                <Typography.Paragraph style={{ color: "#828587", fontSize: "14px", marginTop: 14 }}>
                  Setting one or more goals is optional. However, some students may find it helpful to have a more
                  concrete action plan for the Challenge. You can choose one or more items from the following
                  suggestions. Feel free to edit or create your own.
                </Typography.Paragraph>
              }
            >
              <Radio.Group
                optionType="button"
                buttonStyle="solid"
                value={store.model.goal.enabled}
                onChange={(e) => (store.model.goal.enabled = e.target.value)}
              >
                <Radio.Button value={true}>Yes</Radio.Button>
                <Radio.Button value={false}>No</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              {...formItemLayoutWithOutLabel}
              shouldUpdate={(prevValues, curValues) => prevValues.goal.enabled !== curValues.goal.enabled}
            >
              {({ getFieldValue }) => {
                const show = getFieldValue(["goal", "enabled"]);
                return (
                  show && (
                    <>
                      <Form.Item
                        name={["goal", "description"]}
                        rules={[{ required: true, message: "Please input description." }]}
                      >
                        <Input.TextArea
                          autoSize
                          style={{ minHeight: 100, color: "#191a1d" }}
                          placeholder="Please input description."
                          value={store.model.goal.description}
                          onChange={(e) => (store.model.goal.description = e.target.value)}
                        />
                      </Form.Item>

                      <Form.Item name={["goal", "items"]}>
                        <SelectList
                          value={store.model.goal.items}
                          onChange={(items) => (store.model.goal.items = items)}
                        />
                      </Form.Item>
                    </>
                  )
                );
              }}
            </Form.Item>
          </>
        )}
        {store.curStep === 2 && (
          <>
            <Form.Item
              label="Work Submission"
              required
              name={["workSubmission", "enabled"]}
              extra={
                <Typography.Paragraph style={{ color: "#828587", fontSize: "14px", marginTop: 14 }}>
                  Asking parents to submit their children’s work is also optional. Submitting work via the FooMoo app is
                  easy. You can view all the submissions at the Event page on this site or on the app. Use it as a great
                  opportunity to motivate students, stimulate their creativity and involve parents. Our suggestions are
                  meant to encourage students to have fun in the process. Choose one or more items below based on your
                  students’ age and interests. Please feel free to edit or create your own.
                </Typography.Paragraph>
              }
            >
              <Radio.Group
                optionType="button"
                buttonStyle="solid"
                value={store.model.workSubmission.enabled}
                onChange={(e) => (store.model.workSubmission.enabled = e.target.value)}
              >
                <Radio.Button value={true}>Yes</Radio.Button>
                <Radio.Button value={false}>No</Radio.Button>
              </Radio.Group>
            </Form.Item>
            {store.isWork && (
              <>
                <Form.Item
                  label="Submission Requirement"
                  rules={[{ required: true, message: "Please input description." }]}
                  name={["workSubmission", "description"]}
                >
                  <Input.TextArea
                    autoSize
                    style={{ minHeight: 100, color: "#191a1d" }}
                    value={store.model.workSubmission.description}
                    onChange={(e) => (store.model.workSubmission.description = e.target.value)}
                    placeholder="This will be part of the challenge description for students and parents."
                  />
                </Form.Item>

                <Form.Item name={["workSubmission", "items"]} {...formItemLayoutWithOutLabel}>
                  <SelectList
                    radio={true}
                    value={store.model.workSubmission.items}
                    onChange={(items) => (store.model.workSubmission.items = items)}
                  />
                </Form.Item>
              </>
            )}
          </>
        )}
        {store.curStep === 3 && <ParentPreview />}
        {store.curStep === 4 && <FinishPanel />}
      </Form>

      <StepBtns />
    </Modal>
  );
});
