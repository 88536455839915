import { makeAutoObservable, action, computed } from "mobx";
import moment from "moment";
import { teacher } from "../../domains/Teacher";
import { EditGroupStore } from "../class/EditGroupStore";
import { TeacherStore } from "../TeacherStore";
import { TeachGroupStore } from "../class/TeachGroupStore";

export class TeachSchoolsStore {
  activeSchoolId;

  store: TeacherStore;
  group: TeachGroupStore;
  editGroup: EditGroupStore;

  constructor(store: TeacherStore) {
    makeAutoObservable(this, {
      store: false,
      events: computed,
      inProgress: computed,
      scheduled: computed,
      history: computed,
    });
    this.store = store;
    this.group = new TeachGroupStore(this);
    this.editGroup = new EditGroupStore(this);
  }

  @action
  setActiveSchool(id) {
    this.activeSchoolId = id;
    if (id) {
      console.log("load class", this.school, id);
      this.school?.loadClasses();
      this.school?.loadStudents();
    }
    // if (!this.activeFundId && this.fundraisers.length > 0) {
    //   this.setActiveFund(this.fundraisers[0].id);
    // }
  }

  @action
  checkActiveSchoolId() {
    if (teacher.schools.length === 0) {
      this.setActiveSchool(null);
    } else if (teacher.schools.length === 1) {
      this.setActiveSchool(teacher.schools[0].id);
    } else {
      this.setActiveSchool(teacher.schools[0].id);
    }
  }

  get school() {
    return teacher.schools.find((x) => x.id === this.activeSchoolId);
  }

  get events(): any[] {
    return teacher.schools.find((x) => x.id === this.activeSchoolId)?.events || [];
  }

  get inProgress() {
    return this.events.filter((x: any) => moment(x.start).isBefore(moment()) && moment(x.end).isAfter(moment()));
  }

  get scheduled() {
    return this.events.filter((x: any) => moment(x.start).isAfter(moment()));
  }

  get history() {
    return this.events.filter((x: any) => moment(x.end).isBefore(moment()));
  }

  @action
  async loadParticipants() {
    return this.school?.loadParticipants();
  }
}
