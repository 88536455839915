import { action, makeAutoObservable, runInAction } from "mobx";
import { School } from "./School";

export class Teacher {
  schools: School[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  @action
  update({ schools = [] }) {
    this.schools = schools.map((it) => new School(it));
  }
}

export const teacher = new Teacher();
