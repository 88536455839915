import React, { CSSProperties, FC } from "react";
import { observer } from "mobx-react-lite";
import { QuestionCircleOutlined, CaretDownOutlined } from "@ant-design/icons";

import "./FrCard.scss";
import { Popover, Menu, Dropdown } from "antd";

export const FrCardHeader: FC<{
  cur: number;
  items: any[];
  center?: boolean;
  onClick?: (val: number) => void;
}> = ({ cur, items, center, onClick }) => {
  const menu = (
    <Menu>
      {items.map((x, i) => (
        <Menu.Item key={x.id} onClick={() => onClick && onClick(i)}>
          {x.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]} getPopupContainer={() => document.getElementById("frcard")!}>
      <div
        className="FrCardHeader FrCardSelectHeader"
        style={{
          justifyContent: center ? "center" : "flex-start",
          cursor: "pointer",
        }}
      >
        <div className="title">{items[cur]?.name}</div>
        {items.length > 1 && <CaretDownOutlined className="select" />}
      </div>
    </Dropdown>
  );
};

export const FrCardHeader2: FC<{
  cur: string;
  items: any[];
  center?: boolean;
  onClick?: (val: string) => void;
}> = observer(({ cur, items, center, onClick }) => {
  const menu = (
    <Menu>
      {items.map((x, i) => (
        <Menu.Item key={x.id} onClick={() => onClick && onClick(x.id)}>
          {x.name}
        </Menu.Item>
      ))}
    </Menu>
  );
  const item = items.find((x) => x.id === cur);

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <div
        className="FrCardHeader FrCardSelectHeader"
        style={{
          justifyContent: center ? "center" : "flex-start",
          cursor: "pointer",
        }}
      >
        <div className="title">{item?.name}</div>
        {items.length > 1 && <CaretDownOutlined className="select" />}
      </div>
    </Dropdown>
  );
});

interface FrCardProp {
  title?: string;
  tip?: string;
  style?: CSSProperties;
  bodyStyle?: CSSProperties;
  header?: any;
  onAdd?: () => void;
}

export const FrCard: React.FC<FrCardProp> = observer(({ title, tip, style, bodyStyle, children, header, onAdd }) => {
  const content = <div style={tipStyle}>{tip}</div>;
  return (
    <div className="FrCard" style={style} id="frcard">
      {header || (
        <div className="FrCardHeader">
          <div className="title">{title}</div>
          {!!tip && (
            <Popover placement="bottom" content={content}>
              <QuestionCircleOutlined className="tip" />
            </Popover>
          )}
          {onAdd && (
            <div className="fcc addBtn" onClick={onAdd}>
              <div className="icon" /> Add
            </div>
          )}
        </div>
      )}
      <div className="body" style={bodyStyle}>
        {children}
      </div>
    </div>
  );
});

const tipStyle = {
  maxWidth: 240,
  fontWeight: 400,
  color: "#191a1d",
  fontSize: "12px",
  lineHeight: "18px",
};
