import { makeAutoObservable, runInAction, action } from "mobx";
import Auth from "@aws-amplify/auth";
import { Api } from "../common/Api";
import { defaultQLError } from "../common/Utils";
import { appStore } from "./AppStore";
import { message } from "antd";
import moment from "moment";
import { myHistory } from "../common/History";

export class RegStore {
  schools: any[] = [];
  loadingSchool = false;
  grades: string[] = [];
  keyword = "";
  saving = false;
  isCountDown = false;
  isResend = false;

  model = {
    schoolId: null,
    childFirstName: "",
    childLastName: "",
    grade: "",
    firstName: "",
    lastName: "",
    email: "",
    confirmCode: "",
    password: "",
  };

  constructor() {
    makeAutoObservable(this, {});
  }

  @action edit() {
    this.schools = [];
    this.setKeyword("");
    this.model = {
      schoolId: null,
      childFirstName: "",
      childLastName: "",
      grade: "",
      firstName: "",
      lastName: "",
      email: "",
      confirmCode: "",
      password: "",
    };
  }
  get canSendCode() {
    return !!this.model.email.trim();
  }
  get canSave() {
    const { schoolId, firstName, lastName, grade } = this.model;
    return !!schoolId && !!firstName && !!lastName && !!grade;
  }

  @action
  setKeyword(val) {
    this.keyword = val;

    if (!this.keyword.trim()) {
      this.schools = [];
      this.loadingSchool = false;
      return;
    }

    this.loadingSchool = true;
    Api.reg
      .schools(val)
      .then((res) => {
        runInAction(() => {
          this.schools = res.data.data;
        });
      })
      .finally(() => {
        this.loadingSchool = false;
      });
  }

  @action onSchoolChange(schoolId) {
    this.model.schoolId = schoolId;
    this.model.grade = "";
    const s = this.schools.find((x) => x.id === schoolId);
    this.grades = s ? [...s.grades] : [];
  }

  @action onValuesChange(values) {
    this.model = { ...this.model, ...values };
  }

  @action sendCode() {
    if (!this.canSendCode) return;

    this.isCountDown = true;
    Api.reg
      .emailCode(this.model.email)
      .then((res) => {
        // console.log("res", res);
        setTimeout(() => {
          this.isCountDown = false;
          this.isResend = true;
        }, 6000);
      })
      .catch(() => {
        this.isCountDown = false;
      });
  }

  @action save(values) {
    const { password2, ...other } = values;
    this.saving = true;
    return Api.reg
      .reg({ ...other, startOfDate: moment().startOf("day") })
      .then((res) => {
        if (res.data.code == "0000") {
          return Auth.signIn(values.email, values.password)
            .then((user) => {
              appStore.isLogin = true;
              appStore.loadMe();
              myHistory.push("/fundraiser");
              return true;
            })
            .catch((err) => {
              message.error(err.message);
              return false;
            });
        } else {
          message.error(res.data.msg);
        }
      })
      .finally(() => (this.saving = false));
  }
}

export const regStore = new RegStore();
