import { makeAutoObservable, runInAction, action, computed } from "mobx";
import { Api } from "../../common/Api";
import { ChallengeEvent } from "./ChallengeEvent";
import { Class } from "./Class";
import { Fundraising } from "./Fundraising";
import { SchoolParticipant } from "./SchoolParticipant";
import moment from "moment";
export class School {
  id = null;
  name = "";
  frOrganizers = [];
  classes: Class[] = [];
  events: ChallengeEvent[] = [];
  fundraisings: Fundraising[] = [];
  adminFundraisings: Fundraising[] = [];
  students: any[] = [];
  grades: string[] = [];

  participants: SchoolParticipant[] = [];

  constructor({
    id,
    name,
    frOrganizers = [],
    grades = [],
    fundraisers = [],
    teachEvents = [],
    adminFundraisings = [],
  }) {
    makeAutoObservable(this, {
      inProgressAdminFund: computed,
    });
    this.id = id;
    this.name = name;
    this.grades = grades;
    this.frOrganizers = frOrganizers;
    this.fundraisings = this.createSortedFundraising(fundraisers.map((x) => new Fundraising(x)));
    this.adminFundraisings = adminFundraisings
      .map((x) => new Fundraising(x))
      .sort((a, b) => moment(b.end).valueOf() - moment(a.end).valueOf());
    this.events = teachEvents.map((x) => new ChallengeEvent(x));
  }

  get inProgressAdminFund() {
    return this.adminFundraisings
      .filter((x: any) => x.status === "In Progress")
      .sort((a, b) => moment(a.start).valueOf() - moment(b.start).valueOf());
  }

  @action
  loadClasses() {
    return Api.teacher.classes(this.id).then((data) => {
      runInAction(() => {
        this.classes = data.data.teachClasses.map((x) => new Class(x));
        console.log(this.classes);
        // this.loadStudents();
      });
    });
  }
  @action
  delClass(id) {
    return Api.teacher.delClass(id).then((data) => {
      runInAction(() => {
        this.loadClasses();
      });
    });
  }

  @action
  loadEvents() {
    Api.teacher.events(this.id).then((data) => {
      runInAction(() => {
        this.events = data.data.teachEvents.map((x) => new ChallengeEvent(x));
        console.log(this.events);
      });
    });
  }

  @action
  loadStudents() {
    return Api.teacher.students({ orgId: this.id }).then((data) => {
      runInAction(() => {
        this.students = data.data.students;
      });
    });
  }

  @action
  loadParticipants() {
    return Api.teacher.engParticipants(this.id).then((data) => {
      runInAction(() => {
        this.participants = data.data.engParticipants.map((x) => new SchoolParticipant(x));
      });
      return this.participants;
    });
  }

  @action
  loadAdminFundraisings() {
    return Api.fund.adminFundraisings(this.id).then((data) => {
      const fundraisings = data.data.adminFundraisings
        .map((x) => new Fundraising(x))
        .sort((a, b) => moment(b.end).valueOf() - moment(a.end).valueOf());

      runInAction(() => {
        this.adminFundraisings = fundraisings;
      });
    });
  }

  createSortedFundraising(fundraisers: Fundraising[] = []) {
    const fund0 = fundraisers.filter((x) => x.userStats.userDonated === 0);
    const fund1 = fundraisers.filter((x) => x.userStats.userDonated > 0);
    return [
      ...fund0.sort((a, b) => moment(a.end).valueOf() - moment(b.end).valueOf()),
      ...fund1.sort((a, b) => moment(a.end).valueOf() - moment(b.end).valueOf()),
    ];
  }
}
