import React from "react";

import { Card } from "antd";

export const FmCard = ({ children, style = {} }) => {
  return (
    <Card className="FmCard" style={{ borderRadius: 8, ...style }}>
      {children}
    </Card>
  );
};
