import React from "react";
import { Card, Modal, Layout, Row, Space, Statistic, Table, Typography, Col, Tag } from "antd";
import { FundSchoolsBar } from "./components/FundSchoolsBar";
import { observer } from "mobx-react";
import { appStore } from "../../store/AppStore";
import { fmtMoney, showSuccess, timeDuration } from "../../common/Utils";
import { DeleteOutlined, CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import "./Admin.scss";
import moment from "moment";

const { Paragraph } = Typography;
const { confirm } = Modal;

export const History = observer(() => {
  const { adminFundraisings } = appStore.admin.schoolsStore.school || {};

  const onDel = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    confirm({
      content: "Confirm deletion.",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      icon: null,
      centered: true,
      onOk() {
        appStore.admin.schoolsStore.delFund(id).then(() => {
          showSuccess();
        });
      },
    });
  };

  const columns = [
    {
      title: "Fundraiser",
      key: "name",
      width: 120,
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (_, r) => (
        <div>
          <strong>{r.title || r.customName || r.name}</strong>
          {r.shareCode && <Tag>{r.shareCode}</Tag>}
        </div>
      ),
    },
    {
      key: "del",
      width: 30,
      render: (_, r) =>
        r.status === "Scheduled" || r.status === "Pending Verification" ? (
          <DeleteOutlined onClick={(e) => onDel(e, r.id)} />
        ) : null,
    },
    {
      title: "Status",
      key: "Status",
      dataIndex: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      width: 90,
    },
    {
      title: "Organizer",
      key: "organizer",
      dataIndex: ["organizer", "name"],
      width: 120,
      sorter: (a, b) => a.organizer.name.localeCompare(b.organizer.name),
    },
    {
      title: "Duration",
      width: 120,
      key: "time",
      render: (_, r) => timeDuration(r),
      sorter: (a, b) => moment(a.start).valueOf() - moment(b.start).valueOf(),
    },
    {
      title: "$ Raised",
      key: "raised",
      dataIndex: "reached",
      width: 60,
      render: (v) => fmtMoney(v / 100.0),
      sorter: (a, b) => a.reached - b.reached,
    },
    {
      title: "Enrichment Goals",
      key: "goals",
      width: "20%",
      render: (val, r) =>
        r.type == 1 ? (
          <Paragraph ellipsis={{ rows: 4, expandable: true, symbol: "more" }} style={{ width: "99%" }}>
            {r.count === 1 ? "1 Goal" : `${r.count} Goals`}: {r.description}
          </Paragraph>
        ) : (
          "-"
        ),
    },
    {
      title: "Use of Proceeds",
      key: "raised",
      dataIndex: "proceeds",
      width: "20%",
      render: (val) => (
        <Paragraph ellipsis={{ rows: 4, expandable: true, symbol: "more" }} style={{ width: "99%" }}>
          {val}
        </Paragraph>
      ),
    },
  ];

  const expandable = {
    expandIconColumnIndex: 8,
    expandedRowRender: (r) => (
      <Space className="stats">
        <Statistic title="Number of Student Families Donated" value={r.familyCount} />
        <Statistic title="Donor Family Participation Rate" value={r.familyPercent} precision={0} suffix="%" />
        <Statistic title="Campaign Target Amount" prefix={<small>$</small>} value={r.target} />
        <Statistic title="% Target Reached" value={r.reachPercent} precision={0} suffix="%" />
        {r.type === 1 && (
          <Statistic title="% Students with Completed Activities" precision={0} value={r.goalsPercent} suffix="%" />
        )}
      </Space>
    ),
    expandIcon: ({ expanded, onExpand, record }) =>
      expanded ? (
        <CaretUpOutlined onClick={(e) => onExpand(record, e)} />
      ) : (
        <CaretDownOutlined onClick={(e) => onExpand(record, e)} />
      ),
  };

  return (
    <Layout className="admin">
      <Layout.Header className="headerWrap" style={{ position: "fixed", zIndex: 1000, width: "100%" }}>
        <FundSchoolsBar />
      </Layout.Header>
      <Layout.Content style={{ marginTop: 64, padding: "0 15px 24px 15px" }}>
        <Row className="container admin-history">
          <Col span={24}>
            <Card style={{ marginTop: 20 }} bodyStyle={{ padding: 0 }}>
              <Table
                scroll={{ x: true }}
                showSorterTooltip={false}
                expandable={expandable}
                rowKey="id"
                columns={columns}
                dataSource={adminFundraisings}
                pagination={{ pageSize: 20, position: ["bottomCenter"] }}
              ></Table>
            </Card>
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  );
});
