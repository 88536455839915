import React from "react";
import "./RegPage.scss";
import { Input, Button, Form, Typography, Row, Col, Statistic, Select, Spin } from "antd";
import { Link } from "react-router-dom";
import { regStore } from "../../store/RegStore";
import { Utils } from "../../common/Utils";
import { observer } from "mobx-react";
import { AuthLayout } from "./AuthLayout";
const { Countdown } = Statistic;

export const RegPage = observer(() => {
  const [form] = Form.useForm();
  const store = regStore;

  const save = () => {
    form.validateFields().then((values) => {
      console.log(values);

      store.save(values);
    });
  };

  const onValuesChange = (values) => {
    if (Utils.hasKey(values, "email")) {
      setTimeout(() => {
        if (form.getFieldError("email").length === 0) {
          store.model.email = values.email;
        } else {
          store.model.email = "";
        }
      });
    }
  };

  return (
    <AuthLayout>
      <div className="RegPage">
        <div className="LoginLeft fcc">
          <div className="wrap">
            <div className="title-wrap">
              <div className="title">Community Gateway</div>
              <div className="sub-title">Building the Heart of a Community</div>
            </div>
            <div className="tip">———— Created by Parents for Parents</div>
          </div>
        </div>
        <div className="LoginRight">
          <Form
            form={form}
            layout="vertical"
            name="basic"
            initialValues={{}}
            hideRequiredMark={true}
            onValuesChange={onValuesChange}
            // onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            size="large"
          >
            <Typography.Text>
              You can use one account for multiple children and schools.{" "}
              <Link to="/login" className="tip">
                Log in
              </Link>{" "}
              to add.
            </Typography.Text>
            <Form.Item
              label="School or Organization Name"
              name="schoolId"
              style={{ marginTop: 20 }}
              rules={[{ required: true, message: "Please select school or organization name" }]}
            >
              <Select
                placeholder="Enter school or organization name to search"
                showSearch
                filterOption={false}
                notFoundContent={
                  store.loadingSchool ? (
                    <Spin size="small" />
                  ) : store.keyword.trim() ? (
                    "No data."
                  ) : (
                    "Enter school or organization name to search."
                  )
                }
                onSearch={(val) => store.setKeyword(val)}
                onChange={(val) => {
                  store.onSchoolChange(val);
                  form.setFieldsValue({ grade: null });
                }}
              >
                {store.schools.map((s: any) => (
                  <Select.Option key={s.id} value={s.id}>
                    {s.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Child First Name/Initial"
                  name={["childFirstName"]}
                  hasFeedback
                  rules={[{ required: true, message: "required" }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Grade" name={["grade"]} rules={[{ required: true, message: "required" }]}>
                  <Select placeholder="Select grade">
                    {store.grades.map((x: any) => (
                      <Select.Option value={x} key={x}>
                        {x}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Parent First Name" name="firstName" rules={[{ required: true, message: "required" }]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Parent Last Name" name="lastName" rules={[{ required: true, message: "required" }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={24} sm={16}>
                <Form.Item
                  label="Parent Email Address"
                  name="email"
                  rules={[
                    { required: true, message: "Invalid email address." },
                    {
                      type: "email",
                      message: "New email address is not a valid email.",
                    },
                  ]}
                >
                  <Input
                    addonAfter={
                      <Button
                        type="link"
                        disabled={store.isCountDown}
                        onClick={() => store.sendCode()}
                        style={{ padding: 0, height: 38 }}
                      >
                        <span>{store.isResend ? "Resend" : "Send Code"}</span>
                      </Button>
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={8}>
                <Form.Item
                  label="Confirmation Code"
                  name="confirmCode"
                  rules={[{ required: true, message: "required", pattern: /\d{6}/ }]}
                >
                  <Input maxLength={6} type="number" />
                </Form.Item>
              </Col>
            </Row>
            <Typography.Paragraph style={{ color: "#909090", fontSize: "12px", marginTop: -4 }}>
              We will <Typography.Text style={{ color: "#909090" }}>NOT</Typography.Text> sell any of your info, and we
              will <Typography.Text style={{ color: "#909090" }}>NOT</Typography.Text> spam you.
            </Typography.Paragraph>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input your new password!" },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || (/\d+/.test(value) && /[a-z]+/.test(value) && /[A-Z]+/.test(value))) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      "A minimum of six (6) characters are required, with at least one number, one capital letter and one lowercase letter."
                    );
                  },
                }),
              ]}
            >
              <Input.Password autoComplete="new-password" />
            </Form.Item>

            <Form.Item
              label="Comfirm Password"
              name="password2"
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject("The two passwords that you entered do not match!");
                  },
                }),
              ]}
            >
              <Input.Password autoComplete="new-password" />
            </Form.Item>
            <Typography.Paragraph style={{ color: "#909090", fontSize: "12px", marginTop: -4, marginBottom: 16 }}>
              Require at least one upper case and one lower case letter and at least one number.
            </Typography.Paragraph>
            <Form.Item>
              <Button type="primary" className="btn" block onClick={() => save()} loading={store.saving}>
                Create Account
              </Button>
              <p style={{ textAlign: "center", marginTop: 12 }}>
                <Typography.Text>
                  Have an account?{" "}
                  <Link to="/login" className="tip">
                    Log in
                  </Link>{" "}
                  and Add Other Children or School
                </Typography.Text>
              </p>
            </Form.Item>
          </Form>
        </div>
      </div>
    </AuthLayout>
  );
});
