import { makeAutoObservable, action } from "mobx";
import { fundraiser } from "../domains/Fundraiser";
import { EditFundraisingStore } from "./EditFundraisingStore";
import { FundSchoolsStore } from "./FundSchoolsStore";

export class AdminStore {
  store = null;
  schoolsStore: FundSchoolsStore;
  fundraising: EditFundraisingStore;

  constructor(store) {
    makeAutoObservable(this, {
      store: false,
    });
    this.store = store;
    this.schoolsStore = new FundSchoolsStore(this);
    this.fundraising = new EditFundraisingStore(this);
  }

  @action
  update({ schools = [] }) {
    fundraiser.update({ schools });
    this.schoolsStore.checkActiveSchoolId();
  }
}
