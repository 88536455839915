import React, { useEffect } from "react";
import "./StudentDlg.scss";
import { Modal, Form, Select, Input, Spin } from "antd";
import { appStore } from "../store/AppStore";
import { observer } from "mobx-react";

export const StudentDlg = observer(() => {
  const [form] = Form.useForm();

  const store = appStore.parent.editChild;
  useEffect(() => {
    form.resetFields();
  }, [store.visible]);

  const save = () => {
    form.validateFields().then((values) => {
      console.log(values);

      store.save(values);
    });
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
  };

  return (
    <Modal
      title="Add a Student"
      visible={store.visible}
      maskClosable={false}
      onOk={save}
      okType={store.canSave ? "primary" : "default"}
      okText="Add"
      confirmLoading={store.saving}
      onCancel={() => store.close()}
      width={648}
      getContainer={false}
      destroyOnClose={true}
    >
      <Form form={form} {...layout} onValuesChange={(values) => store.onValuesChange(values)}>
        <Form.Item label="School" name="schoolId" rules={[{ required: true, message: "Please select school name" }]}>
          <Select
            placeholder="Enter school name to search"
            showSearch
            filterOption={false}
            notFoundContent={
              store.loadingSchool ? (
                <Spin size="small" />
              ) : store.keyword.trim() ? (
                "No data."
              ) : (
                "Enter school name to search."
              )
            }
            onSearch={(val) => appStore.parent.editChild.setKeyword(val)}
            onChange={(val) => {
              store.onSchoolChange(val);
              form.setFieldsValue({ grade: null });
            }}
          >
            {appStore.parent.editChild.schools.map((s: any) => (
              <Select.Option key={s.id} value={s.id}>
                {s.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Child First Name"
          name="firstName"
          rules={[{ required: true, message: "Please input first name" }]}
        >
          <Input placeholder="Enter first name" />
        </Form.Item>

        <Form.Item label="Grade" name="grade" rules={[{ required: true, message: "Please select grade" }]}>
          <Select placeholder="Select grade">
            {store.grades.map((x: any) => (
              <Select.Option value={x} key={x}>
                {x}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
});
