import { gql } from "apollo-boost";

export const teach = gql`
  {
    teach {
      schools {
        id
        name
      }
    }
  }
`;

const teachClasses = gql`
  query teachClasses($orgId: String!) {
    teachClasses(orgId: $orgId) {
      classId
      orgId
      name
    }
  }
`;

export const students = gql`
  query students($filter: OrgStudentFilter!) {
    students(filter: $filter) {
      childId
      name
      grade
    }
  }
`;

const engParticipants = gql`
  query engParticipants($orgId: String!) {
    engParticipants(orgId: $orgId) {
      typeId
      type
      title
      grades
      count
    }
  }
`;

const teachEvents = gql`
  query teachEvents($orgId: String!) {
    teachEvents(orgId: $orgId) {
      id
      type
      name
      customName
      title
      start
      end
      shareCode
      participant {
        title
      }
      students {
        id
        childId
        status
        child {
          name
        }
      }
    }
  }
`;

export const saveClass = gql`
  mutation saveClass($input: OrgClassInput!) {
    saveClass(input: $input) {
      classId
      orgId
      name
      students {
        childId
        name
        grade
      }
    }
  }
`;

export const delClass = gql`
  mutation delClass($classId: String!) {
    delClass(classId: $classId)
  }
`;

export const teachGql = {
  teach,
  teachClasses,
  saveClass,
  delClass,
  students,
  teachEvents,
  engParticipants,
};
