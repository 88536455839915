import { FormInstance } from "antd/lib/form";
import { makeAutoObservable, computed, action, runInAction } from "mobx";
import moment from "moment";
import { Api } from "../../../common/Api";
import { defaultQLError, isArrayInArray, showSuccess } from "../../../common/Utils";
import { EngDlgUtils } from "../../../pages/teacher/components/ChallengeDlg/EngDlgUtils";
import { TeacherStore } from "../TeacherStore";
import { teacher } from "../../domains/Teacher";

const stepCount = 5;

export class EditChallengeStore {
  store: TeacherStore;
  visible = false;
  participant: any = null;
  form?: FormInstance;
  curStep = 0;
  saving = false;
  engTool = {
    activityTypes: [],
    activities: [],
    workSubmissions: [],
  };
  isCustomName = false;
  isWork = true;

  model = {
    type: null,
    participantId: null,
    orgId: null,
    name: "",
    customName: "",
    description: "",
    range: [moment(), moment().add(7, "days")],
    addName: "None",
    goal: { enabled: true, description: "", items: [] },
    workSubmission: { enabled: true, description: "", items: [] },
    parentHelp: "",
  };

  constructor(store: TeacherStore) {
    makeAutoObservable(this, {
      store: false,
      toolActivities: computed,
      school: computed,
      participants: computed,
      addGroupName: computed,
      canNext: computed,
    });
    this.store = store;
    this.loadTools();
  }

  get toolActivities() {
    if (this.participant) {
      return this.engTool.activities.filter(
        (x: any) => x.activityType === this.model.type && isArrayInArray(x.grades, this.participant.grades)
      );
    } else {
      return this.engTool.activities.filter((x: any) => x.activityType === this.model.type);
    }
  }

  get school() {
    return teacher.schools.find((x) => x.id === this.model.orgId);
  }

  get participants() {
    return this.school?.participants || [];
  }

  get canNext() {
    return EngDlgUtils.validNext(this.curStep, this.model);
  }

  get addGroupName() {
    if (this.model.addName === "Group" && this.participant) {
      return `${this.participant.type === "School" ? this.school?.name : this.participant.title}'s `;
    }

    return "";
  }

  @action
  onSchoolChange(orgId) {
    this.model.orgId = orgId;
    this.school?.loadParticipants();
    this.participant = null;
    this.form!.setFieldsValue({ participantId: undefined });
  }

  @action
  onParticipantChange(participantId) {
    this.model.participantId = participantId;
    this.participant = this.participants.find((x: any) => x.type + x.typeId === participantId);
    this.model.name = "";
    this.form!.setFieldsValue({ name: "" });
    this.isCustomName = false;
    this.model.parentHelp = "";
    this.updateGoals();
    this.updateWorkSubmission();
  }

  @action
  onTypeChange(type) {
    this.model.type = type;
    this.model.name = "";
    this.form!.setFieldsValue({ name: "" });
    this.isCustomName = false;
    this.model.parentHelp = "";
    this.updateGoals();
    this.updateWorkSubmission();
  }

  @action
  onNameChange(name) {
    this.isCustomName = name === "-";
    this.model.name = name;
    if (!this.isCustomName) {
    }
    this.updateGoals();
  }

  @action
  onAddNameChange(addName) {
    this.model.addName = addName;
  }

  @action
  updateWorkSubmission() {
    if (!this.model.type) return;

    let defaultWork = this.engTool.workSubmissions.filter((x: any) => x.activityType === this.model.type);
    if (this.participant) {
      defaultWork = defaultWork.filter((x: any) => isArrayInArray(x.grades, this.participant.grades.slice(-1)));
    }

    const workSubmission: any = {
      enabled: true,
      items: defaultWork.map(({ title, description }) => ({
        value: `${title}: ${description}`,
        checked: false,
      })),
      description: `We encourage our participants to submit work based on the follow suggestion(s). It’s optional.
Unleash your children’s creativity. This is not homework but an opportunity to find joy in learning.
  `,
    };
    this.form!.setFieldsValue({ workSubmission });
    this.model = { ...this.model, workSubmission };
  }

  @action updateGoals() {
    const it: any = this.toolActivities.find((x: any) => x.activityName === this.model.name) || {};
    const { description = "", parentHelp = "", goalsGradeAll = true } = it;
    let items = [];
    if (goalsGradeAll || !this.participant) {
      items = (it.goalsItems?.[0]?.items || []).map((value) => ({ value, checked: false }));
    } else {
      for (let i = it.goalsItems.length - 1; i > 0; i--) {
        if (this.participant.grades.includes(it.goalsItems[i].title)) {
          items = (it.goalsItems[i].items || []).map((value) => ({ value, checked: false }));
          break;
        }
      }
    }

    const goal = {
      enabled: true,
      description: it.goalsTeacher || "",
      items,
    };
    this.form!.setFieldsValue({
      description: description,
      goal,
    });
    this.model = { ...this.model, description, goal, parentHelp };
  }

  @action
  loadTools() {
    Api.teacher.engTool().then((res) => {
      runInAction(() => {
        this.engTool = res.data.engTool;
      });
    });
  }

  @action
  show() {
    this.curStep = 0;
    this.visible = true;
    this.model = {
      type: null,
      participantId: null,
      orgId: null,
      name: "",
      customName: "",
      description: "",
      range: [moment(), moment().add(7, "days")],
      addName: "None",
      goal: { enabled: true, description: "", items: [] },
      workSubmission: { enabled: true, description: "", items: [] },
      parentHelp: "",
    };
    this.loadTools();
    this.model.orgId = this.store.schoolsStore.activeSchoolId;
    this.school?.loadParticipants();
    this.participant = null;
    this.isCustomName = false;
  }

  @action
  back() {
    if (this.curStep > 0) {
      this.curStep = this.curStep - 1;
    } else {
      this.close();
    }
  }

  @action
  next() {
    if (!this.form || this.saving) return;

    this.form.validateFields().then((values) => {
      this.model = { ...this.model, ...values };
      if (this.curStep < stepCount - 1) {
        this.curStep = this.curStep + 1;
        // this.canNext = EngDlgUtils.validNext(this.curStep + 1, this.model);
        return;
      }
      console.log("save", this.model);

      const { range, participantId, goal, workSubmission, ...other } = this.model;
      const start = range[0].startOf("day").toISOString();
      const end = range[1].endOf("day").toISOString();
      this.saving = true;
      Api.teacher
        .saveEngActivity({
          ...other,
          start,
          end,
          // orgId: this.store.schoolsStore.activeSchoolId,
          participant: this.participant,
          goal: {
            ...goal,
            description: goal.enabled ? goal.description.trim() + "\n" : "",
            items: goal.enabled
              ? goal.items.filter((x: any) => x.checked && !!x.value.trim()).map((x: any) => x.value.trim())
              : [],
          },
          workSubmission: {
            ...workSubmission,
            description: workSubmission.enabled ? workSubmission.description.trim() + "\n" : "",
            items: workSubmission.enabled
              ? workSubmission.items.filter((x: any) => x.checked && !!x.value.trim()).map((x: any) => x.value.trim())
              : [],
          },
        })
        .then((res) => {
          showSuccess();
          this.curStep = 0;
          this.close();
        })
        .catch((err) => defaultQLError(err))
        .finally(() => (this.saving = false));
    });
  }

  close() {
    this.visible = false;
    this.store.schoolsStore.school?.loadEvents();
  }
}
