import { action, makeAutoObservable, runInAction } from "mobx";
import { Api } from "../../common/Api";
import { Child } from "./Child";
import { School } from "./School";

export class Parent {
  schools: School[] = [];
  children: Child[] = [];
  donates = [];
  payMethods = [];

  constructor() {
    makeAutoObservable(this);
  }

  getChildName(childId) {
    const child = this.children.find((x) => x.childId === childId);
    return child?.firstName || "";
  }

  @action
  update({ children = [], schools = [], donates = [] }) {
    this.schools = schools.map((it) => new School(it));
    this.children = children.map((x) => new Child(x));
    this.donates = donates || [];
  }

  @action
  loadParent() {
    Api.parent.parent().then((res) => {
      runInAction(() => {
        this.update(res.data.parent);
      });
    });
  }

  @action
  loadPayMethods() {
    Api.parent.payMethods().then((res) => {
      runInAction(() => {
        this.payMethods = res.data.payMethods;
      });
    });
  }
}

export const parent = new Parent();
