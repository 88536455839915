import React from "react";
import { Button, Space } from "antd";
import { appStore } from "../../../../store/AppStore";
import { observer } from "mobx-react";

export const StepBtns = observer(() => {
  const { curStep, canNext, saving } = appStore.teach.challenge;
  return (
    <div className="step-btns" style={{ textAlign: "center" }}>
      <Space>
        <Button size="large" onClick={() => appStore.teach.challenge.back()}>
          {curStep > 0 ? "Back" : "Cancel"}
        </Button>
        <Button
          size="large"
          type="primary"
          loading={saving}
          onClick={() => appStore.teach.challenge.next()}
          disabled={!canNext}
        >
          {curStep < 5 - 1 ? "Next" : "Submit"}
        </Button>
      </Space>
    </div>
  );
});
