import { gql } from "apollo-boost";
import { fragment } from "./fragment";

const findInviteEmail = gql`
  query ($id: String!) {
    findInviteEmail(id: $id) {
      id
      email
      orgName
    }
  }
`;

const payMethods = gql`
  {
    payMethods {
      id
      brand
      last4
      name
      isDefault
      isExpired
    }
  }
`;

const app = gql`
  {
    me {
      isAdmin
      isTeacher
    }
    teach {
      schools {
        id
        name
      }
    }
  }
`;

const me = gql`
  {
    me {
      name
      email
      firstName
      isAdmin
      isTeacher
      isParent
      teach {
        schools {
          id
          name
          grades
          teachEvents {
            id
            type
            name
            customName
            shareCode
            title
            start
            end
            participant {
              title
            }
          }
        }
      }
      admin {
        schools {
          id
          name
          frOrganizers {
            id
            name
          }
          adminFundraisings {
            id
            type
            name
            start
            end
            status
            proceeds
            suggested
            target
            description
            count
            reached
            familyCount
            totalStudents
          }
        }
      }
      parent {
        ...parentInfo
      }
    }
  }
  ${fragment.parentInfo}
`;

const searchSchools = gql`
  query searchSchools($filter: OrgFilter) {
    searchSchools(filter: $filter) {
      id
      name
      grades
    }
  }
`;

export const setupSecret = gql`
  mutation setupSecret {
    setupSecret {
      clientSecret
    }
  }
`;

const inviteInitPwd = gql`
  mutation inviteInitPwd($id: String!, $pwd: String!) {
    inviteInitPwd(id: $id, pwd: $pwd)
  }
`;

export const sendEmailCode = gql`
  mutation sendEmailCode($email: String!) {
    sendEmailCode(email: $email)
  }
`;

export const updateEmail = gql`
  mutation updateEmail($email: String!, $code: String!) {
    updateEmail(email: $email, code: $code)
  }
`;

export const FrDonate = gql`
  mutation donate($input: DonateInput!) {
    donate(input: $input) {
      clientSecret
    }
  }
`;

const delPayMethod = gql`
  mutation delPayMethod($id: String!) {
    delPayMethod(id: $id)
  }
`;

const saveChild = gql`
  mutation saveChild($input: SaveChildByUserInput!) {
    saveChild(input: $input)
  }
`;

const delChild = gql`
  mutation delChild($childId: String!) {
    delChild(childId: $childId)
  }
`;

const payMethodAdded = gql`
  mutation payMethodAdded($id: String!) {
    payMethodAdded(id: $id)
  }
`;

export const hi = gql`
  {
    hi
  }
`;

export const hiSub = gql`
  subscription Sub {
    sub {
      name
    }
  }
`;

export const meGql = {
  app,
  me,
  findInviteEmail,
  inviteInitPwd,
  searchSchools,
  saveChild,
  delChild,
  hi,
  hiSub,
  setupSecret,
  payMethods,
  payMethodAdded,
  delPayMethod,
  sendEmailCode,
  updateEmail,
};
