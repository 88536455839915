import gql from "graphql-tag";

const parentInfo = gql`
  fragment parentInfo on Parent {
    children {
      childId
      firstName
      lastName
      school {
        id
        name
      }
      grade
      pending {
        firstName
        lastName
        grade
        schoolId
        schoolName
      }
    }
    schools {
      id
      name
      fundraisers {
        id
        type
        name
        start
        end
        shareCode
        proceeds
        suggested
        target
        description
        count
        reached
        donateCount
        totalStudents
        joinedStudents
        goalCompletedCount
        goals {
          id
          childId
          goals {
            completed
            description
          }
        }
        userStats {
          userDonated
          extended
          ranking
        }
        familyDonates {
          id
          inviteName
          inviteType
          inviteData
          amount
          status
          remind
        }
      }
    }
    donates {
      id
      createDate
      amount
      fundId
      fundraiser {
        id
        name
        organizer {
          id
          name
        }
      }
    }
  }
`;

export const fragment = {
  parentInfo,
};
