import React, { useEffect } from "react";
import { Modal, Input, Select, Form, InputNumber, DatePicker, Button, Popover, Col, Row } from "antd";
import { Utils } from "../../../common/Utils";
import { FrTypes } from "../../../common/Fr";
import "./fundraiser.scss";
import { observer } from "mobx-react";
import { appStore } from "../../../store/AppStore";
import moment from "moment";
import { MoneyInput } from "../../../components/MoneyInput";
import { fundraiser } from "../../../store/domains/Fundraiser";
import { MinusCircleOutlined, PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;

const dateFormat = "M/D/YYYY";

export const indexStr = ["1st", "2nd", "3rd", "4th", "5th", "6th"];

export const FundraiserDlg = observer(() => {
  const store = appStore.admin.fundraising;

  const [form] = Form.useForm();
  store.form = form;

  useEffect(() => {
    form.resetFields();
  }, [store.visible]);

  const next = () => {
    form.validateFields().then((values) => {
      console.log("values", values);
      const { range, ...other } = values;
      const start = range[0].startOf("day").toISOString();
      const end = range[1].endOf("day").toISOString();
      store.save({ ...other, start, end }).then(() => {
        Modal.success({
          content: (
            <span style={{ fontSize: "16px", display: "block", textAlign: "left" }}>
              Your fundraiser has been submitted for Community Director approval. Once approved, emails will go out to
              participants on the morning of the scheduled start date. You can check the Fundraiser History tab for
              status update.
            </span>
          ),
          okText: "Close",
          width: 600,
          centered: true,
        });
      });
    });
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 14 },
  };

  const onValuesChange = (values) => {
    if (Utils.hasKey(values, "type")) {
      store.onTypeChange(values.type);
    } else if (Utils.hasKey(values, "orgId")) {
      store.onSchoolChange(values.orgId);
    }
  };

  const onClose = () => {
    const values = form.getFieldsValue();

    console.log("values", values);
    if (Object.values(values).every((x) => !x)) {
      store.close();
      return;
    }

    Modal.confirm({
      title: "Leave Page & Discard Changes?",
      content:
        "Your changes won't be saved. To keep our gateway simple, we are not saving drafts of fundraiser proposals. If you cannot complete it right now, you may want to save any lengthy writing into a different application for you to use later. Thank you for your understanding.",
      okText: "Stay",
      className: "leave-page",
      onOk: () => {},
      cancelText: "Leave",
      onCancel: () => {
        store.close();
      },
    });
  };

  return (
    <Modal
      title="Start a New Fundraiser"
      visible={store.visible}
      maskClosable={false}
      // onOk={save}
      confirmLoading={store.saving}
      onCancel={() => onClose()}
      width={760}
      getContainer={false}
      footer={false}
      className="FundraiserDlg"
    >
      <Form
        id="newFund"
        form={form}
        {...layout}
        colon={false}
        initialValues={store.model}
        onValuesChange={onValuesChange}
      >
        {fundraiser.schools.length > 1 && (
          <Form.Item
            label="Choose School"
            name="orgId"
            rules={[{ required: true, message: "Please select a school." }]}
          >
            <Select getPopupContainer={() => document.getElementById("newFund")!}>
              {fundraiser.schools.map((x: any) => (
                <Select.Option value={x.id} key={x.id}>
                  {x.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item label="Type of Fundraiser" name="type">
          <Select getPopupContainer={() => document.getElementById("newFund")!}>
            {FrTypes.map((x, i) => (
              <Select.Option value={i} key={i}>
                {x}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Fundraiser Organizer"
          name="funOrgId"
          rules={[{ required: true, message: "Please select organizer" }]}
        >
          <Select
            placeholder="PTA, Sports, Team, School etc"
            getPopupContainer={() => document.getElementById("newFund")!}
          >
            {store.organizers.map((x: any) => (
              <Select.Option value={x.id} key={x.id}>
                {x.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Name of Fundraiser" name="name" rules={[{ required: true, message: "Please input name" }]}>
          <Input maxLength={60} placeholder="Enter name" />
        </Form.Item>

        {store.model.type < 2 && (
          <>
            <div style={{ marginBottom: 10 }}>
              <label>
                Donor Tiering (optional from low to hight){" "}
                <Popover
                  content={
                    <div>
                      This is optional. You may use “Suggested Donation” or multiple tiers such as “President’s Circle”,
                      “Community Leader”.. <br />
                      If you enter a “Tier Name”, then a dollar amount is required for that tier.
                    </div>
                  }
                  trigger="hover"
                >
                  <QuestionCircleOutlined />
                </Popover>
              </label>
            </div>

            <Form.List name="suggestions">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, fieldKey, ...restField }, idx) => (
                    <Row key={key} style={{ marginBottom: 8 }} gutter={4}>
                      <Col xs={0} sm={4}></Col>
                      <Col xs={10} sm={8}>
                        <Form.Item
                          {...restField}
                          labelCol={{ span: 13 }}
                          wrapperCol={{ span: 11 }}
                          name={[name, "label"]}
                          label={`${indexStr[idx]} Tier Name`}
                          fieldKey={[fieldKey, "label"]}
                          rules={[{ required: true, message: "Missing tier name" }]}
                        >
                          <Input maxLength={20} />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={10}>
                        <Form.Item
                          {...restField}
                          labelCol={{ span: 15 }}
                          wrapperCol={{ span: 9 }}
                          name={[name, "amount"]}
                          label={`${indexStr[idx]} Tier $ Threshold $`}
                          style={{ width: 300 }}
                          fieldKey={[fieldKey, "amount"]}
                          rules={[{ required: true, message: "Missing tier threshold" }]}
                        >
                          <InputNumber min={1} size="large" />
                        </Form.Item>
                      </Col>
                      <Col span={2} style={{ textAlign: "right" }}>
                        {fields.length > 1 && <MinusCircleOutlined onClick={() => remove(name)} />}
                      </Col>
                    </Row>
                  ))}
                  {fields.length < 3 && (
                    <Row gutter={4}>
                      <Col xs={0} sm={8}></Col>
                      <Col xs={24} sm={12}>
                        <Form.Item>
                          <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                            Add Tier
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </Form.List>
          </>
        )}
        <Form.Item
          label="Total Fundraiser Target"
          name="target"
          rules={[{ required: true, message: "Please input target" }]}
        >
          <MoneyInput style={{ fontSize: "16px", width: "80%", height: 32, lineHeight: "32px" }} />
        </Form.Item>
        <Form.Item
          label="Time Duration"
          name="range"
          rules={[{ required: true, message: "Please input Time Duration" }]}
          extra="Recommend: fundraiser ends on the date of or date before event."
        >
          <RangePicker
            getPopupContainer={() => document.getElementById("newFund")!}
            inputReadOnly
            format={dateFormat}
            disabledDate={(current) => current && current < moment().endOf("day")}
          />
        </Form.Item>

        {store.model.type === 1 && (
          <>
            <Form.Item
              label="Description of Enrichment Goals"
              name="description"
              rules={[{ required: true, message: "Please input description" }]}
            >
              <Input.TextArea
                maxLength={500}
                style={{ minHeight: 120 }}
                placeholder="Eg. We ask each student to perform at least 2 
              community goals they define etc"
              />
            </Form.Item>
            <Form.Item
              label={`Number of ${["", "Goals", "Activity"][store.model.type]}`}
              name="count"
              rules={[{ required: true, message: "Please input number" }]}
            >
              <InputNumber min={0} precision={0} max={10} />
            </Form.Item>
          </>
        )}

        {store.model.type == 2 && (
          <>
            <Form.Item label={`Minimum donation / donator`} name={["perGoal", "minimum"]}>
              <InputNumber min={0} placeholder="Optional. Kick off with at least $10.00." />
            </Form.Item>

            <Form.Item
              label={
                <div>
                  Describe Donation for <br />
                  each Goal Achieved
                </div>
              }
              name={["perGoal", "description"]}
              rules={[{ required: true, message: "Please input" }]}
              extra="This the title language for the donation box for donor to fill out."
            >
              <Input.TextArea
                style={{ minHeight: 60 }}
                maxLength={240}
                placeholder="Example: Donation per Hit, Donation per Lap, Donation / Home Run"
              />
            </Form.Item>

            <Form.Item
              label="Describe the goal in singular"
              name={["perGoal", "singular"]}
              rules={[{ required: true, message: "Please input singular" }]}
            >
              <Input maxLength={60} placeholder="example: run, lap, book" />
            </Form.Item>

            <Form.Item
              label="Describe the goal in plural"
              name={["perGoal", "plural"]}
              rules={[{ required: true, message: "Please input plural" }]}
            >
              <Input maxLength={60} placeholder="example: runs, laps, books" />
            </Form.Item>
          </>
        )}
        <Form.Item
          label={"Describe Fundraising & Purpose"}
          // label={store.model.type == 2 ? "Describe Fundraising & Purpose" : "Use of Proceeds"}
          name="proceeds"
          rules={[{ required: true, message: "Please input use of proceeds" }]}
        >
          <Input.TextArea
            autoSize
            maxLength={500}
            style={{ minHeight: 120 }}
            placeholder="This language will be displayed on the fundraiser dashboard page for parents. Useful background on the primary uses of the funds will help parents make their donation decision."
          />
        </Form.Item>
        <Form.Item label="Summary" name="summary" rules={[{ required: true, message: "Please input summary" }]}>
          <Input.TextArea
            autoSize
            maxLength={500}
            style={{ minHeight: 80 }}
            placeholder="Use one sentence to describe use of funds, to be used in the invitation email."
          />
        </Form.Item>
        <Form.Item {...{ wrapperCol: { sm: { span: 14, offset: 8 }, xs: { span: 24, offset: 0 } } }}>
          <Button type="primary" onClick={next} block loading={store.saving}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
});
