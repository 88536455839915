import React from "react";
import "./Home.scss";
import { FrCard, FrCardHeader2 } from "../../components/FrCard";
import { FrBar } from "../../components/FrBar";
import { fmtMoney, timeDuration } from "../../common/Utils";
import { Col, Row, Tag, Typography } from "antd";

export const Fundraiser = ({ fund, fundraisers, curFund, onChange }) => {
  return (
    <FrCard
      title="PTA 2020 Spring Fundraiser"
      style={{ height: "100%", marginTop: 0 }}
      bodyStyle={{ padding: "20px 18px 0 18px", minHeight: 320 }}
      header={<FrCardHeader2 cur={curFund} items={fundraisers || []} onClick={onChange} />}
    >
      <Row style={{ display: "flex" }}>
        <Col className="text" xs={24} md={16} style={{ marginBottom: 20 }}>
          <Typography.Paragraph>{timeDuration(fund)}</Typography.Paragraph>
          {fund.shareCode && <Tag>Share Code: {fund.shareCode}</Tag>}
          {fund.proceeds.split("\n").map((l, i) => (
            <div key={i + ""}>{l}</div>
          ))}
        </Col>
        <Col
          xs={24}
          md={8}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <FrBar
            title={`Target ${fmtMoney(fund.target)}`}
            info="of Target Reached"
            percent={(fund.reached || 0) / fund.target}
            style={{ width: 110 }}
          />
        </Col>
      </Row>
    </FrCard>
  );
};
