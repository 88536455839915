import React from "react";
import "./Admin.scss";
import { observer } from "mobx-react";
import { Row, Col, Typography, Layout } from "antd";
import { FrCard } from "../../components/FrCard";
import { FrBar } from "../../components/FrBar";
import { fmtDate, fmtMoney } from "../../common/Utils";
import { Top10 } from "./Top10";
import { Management } from "./Management";
import { FrTypes } from "../../common/Fr";
import { FundSchoolsBar } from "./components/FundSchoolsBar";
import { appStore } from "../../store/AppStore";
import { SelectCardHeader } from "../../components/SelectCardHeader";
const { Paragraph } = Typography;

export const Admin = observer(() => {
  const { inProgress = [], activeFundId, activeFund } = appStore.admin.schoolsStore;

  const header = (
    <SelectCardHeader
      curId={activeFundId}
      items={inProgress}
      onClick={(id) => appStore.admin.schoolsStore.setActiveFund(id)}
    />
  );

  const line = (label, val) => (
    <Row className="line" gutter={16}>
      <Col span={8} style={{ fontWeight: 400 }}>
        {label}
      </Col>
      <Col span={16} style={{ textAlign: "right" }}>
        {val}
      </Col>
    </Row>
  );

  return (
    <Layout className="admin">
      <Layout.Header className="headerWrap" style={{ position: "fixed", zIndex: 1000, width: "100%" }}>
        <FundSchoolsBar />
      </Layout.Header>
      <Layout.Content style={{ marginTop: 64, paddingBottom: 24 }}>
        <div className="container">
          <div className="admin">
            {inProgress.length > 0 && (
              <Row>
                <Col span={24}>
                  <FrCard title="ABC School 2020 Spring Fundraiser" header={header} bodyStyle={{ paddingTop: 10 }}>
                    <Row>
                      <Col xs={24} md={12}>
                        {line("Type of Fundraising", FrTypes[activeFund?.type])}
                        {line("Duration", `${fmtDate(activeFund?.start)} - ${fmtDate(activeFund?.end)}`)}
                        {line("Target Amount", `${fmtMoney(activeFund?.target)}`)}
                        {line("Fundraiser", activeFund?.organizer?.name)}
                        <Row gutter={16} className="line">
                          <Col span={8} style={{ fontWeight: 400 }}>
                            Use of Funds
                          </Col>
                          <Col span={16}>
                            <Paragraph
                              ellipsis={{ rows: 3, expandable: true }}
                              style={{ color: "#828587", lineHeight: "25px", textAlign: "right" }}
                            >
                              {activeFund?.proceeds}
                            </Paragraph>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={24} md={12}>
                        <div className="bars">
                          <FrBar
                            title={`${fmtMoney((activeFund?.reached || 0) / 100.0)} Raised`}
                            info="Target Reached"
                            percent={activeFund?.reachPercent || 0}
                            style={{ width: 114 }}
                          />
                          <FrBar
                            title={`${activeFund?.familyCount || 0} ${
                              (activeFund?.familyCount || 0) > 1 ? "Families" : "Family"
                            } Donated`}
                            info="Community Participated"
                            percent={activeFund?.familyPercent || 0}
                            style={{ width: 114 }}
                            color="#9CA4AA"
                          />
                          {activeFund?.type === 1 && (
                            <FrBar
                              title={`${activeFund?.goalCompletedCount || 0} ${
                                (activeFund?.goalCompletedCount || 0) > 1 ? "Students" : "Student"
                              } Reached Goals`}
                              info="Service of Goals Set"
                              percent={activeFund?.goalsPercent || 0}
                              style={{ width: 114 }}
                              color="#B8BEC0"
                            />
                          )}
                        </div>
                      </Col>
                    </Row>
                  </FrCard>
                </Col>
              </Row>
            )}
            {inProgress.length > 0 && activeFund ? (
              <Row gutter={20}>
                <Col xs={24} sm={12}>
                  {inProgress.length > 0 && activeFund && <Top10 donors={activeFund.top10Families} />}
                </Col>
                <Col xs={24} sm={12}>
                  <Management />
                </Col>
              </Row>
            ) : (
              <Row gutter={20}>
                <Col xs={24} sm={{ offset: 6, span: 12 }}>
                  <Management title="No Active Fundraiser in Progress" />
                </Col>
              </Row>
            )}
          </div>
        </div>
      </Layout.Content>
    </Layout>
  );
});
