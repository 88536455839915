import Auth from "@aws-amplify/auth";

Auth.configure({
  identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
  region: process.env.REACT_APP_AWS_REGION,
  userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID
});

const getUserId = () => {
  return Auth.currentAuthenticatedUser().then(user => user?.attributes?.sub);
};

export const Aws = {
  getUserId
};
