import { makeAutoObservable, autorun, runInAction, action, computed } from "mobx";
import { School } from "./School";

export class Child {
  childId = null;
  school: School | null = null;
  firstName = "";
  // lastName = "";
  grade = "";

  pending?: {
    firstName: string;
    // lastName: string;
    grade: string;
    schoolId: string;
    schoolName: string;
  };

  constructor({
    childId = null,
    firstName = "",
    // lastName = "",
    grade = "",
    school = null,
    pending = undefined,
  }: Partial<Child> = {}) {
    makeAutoObservable(this, {
      hasReject: computed,
    });
    this.childId = childId;
    this.firstName = firstName;
    // this.lastName = lastName;
    this.grade = grade;
    this.school = school;
    this.pending = pending;
  }

  get hasReject() {
    const { firstName, grade, school } = this;
    return !firstName || !grade || !school;
  }
}
