import Auth from "@aws-amplify/auth";
import { WebSocketLink } from "apollo-link-ws";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { split } from "apollo-link";
import { getMainDefinition } from "apollo-utilities";
import { createHttpLink } from "apollo-link-http";
import { ApolloLink, Observable } from "apollo-link";

const request = async (opt) => {
  if (process.env.NODE_ENV === "production") {
    let token = "";
    try {
      token = (await Auth.currentSession()).getIdToken().getJwtToken();
    } catch (err) {
      console.log("token", err);
    }
    opt.setContext({
      headers: {
        Authorization: token, // (await Auth.currentSession()).getIdToken().getJwtToken(),
      },
    });
  } else {
    opt.setContext({
      headers: {
        UserId: "user1",
      },
    });
  }
};

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle;
      Promise.resolve(operation)
        .then((oper) => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);

const httpLink = ApolloLink.from([
  requestLink,
  createHttpLink({
    uri: `${process.env.REACT_APP_ADMIN_API}/graphql`,
  }),
]);

const wsLink = new WebSocketLink({
  uri: `ws://localhost:3001/`,
  options: {
    reconnect: true,
  },
});
const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === "OperationDefinition" && definition.operation === "subscription";
  },
  wsLink,
  httpLink
);

export const client = new ApolloClient({
  cache: new InMemoryCache({ addTypename: false }),
  link,
});
