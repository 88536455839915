import React, { useEffect } from "react";
import { Form, Input, Button, Typography } from "antd";
import { Link, useHistory, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { appStore } from "../../store/AppStore";
import "./LoginPage.scss";
import { LockOutlined } from "@ant-design/icons";

export const InvitePage = observer(() => {
  const history = useHistory();
  let { id } = useParams<any>();
  const store = appStore.inviteStore;

  useEffect(() => {
    store.loadInvite(id);
  }, []);

  const onFinish = ({ password }: any) => {
    if (!store.inviteInfo?.email) {
      return;
    }

    store.initPwd(password).then((ok) => {
      if (ok) {
        history.push("/");
      }
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="LoginPage">
      <div className="LoginLeft fcc">
        <div className="wrap">
          <div className="title-wrap">
            <div className="title">Community Gateway</div>
            <div className="sub-title">Building the Heart of a Community</div>
          </div>
          <div className="tip">———— Created by Parents for Parents</div>
        </div>
      </div>
      <div className="LoginRight fcc">
        <Form
          layout="vertical"
          name="basic"
          initialValues={{}}
          hideRequiredMark={true}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          size="large"
        >
          <div className="invite-header">New Account Setup</div>
          <div className="invite-email">
            {store.loading && <div>loading...</div>}
            {!store.loading && <div>{store.inviteInfo?.email || "Not Found!"}</div>}
          </div>

          <Form.Item
            label="New Password"
            name="password"
            dependencies={["password"]}
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />} />
          </Form.Item>

          <Form.Item
            label="Comfirm Password"
            name="password2"
            dependencies={["password"]}
            rules={[
              { required: true, message: "Please confirm your password!" },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("The two passwords that you entered do not match!");
                },
              }),
            ]}
          >
            <Input.Password prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />} />
          </Form.Item>
          <p className="help" style={{ marginTop: 8 }}>
            Require at least one upper case and one lower case letter and at least one number.
          </p>
          <Form.Item style={{ marginBottom: 16 }}>
            <Button type="primary" className="btn" block htmlType="submit" loading={store.saving}>
              Create Account
            </Button>
          </Form.Item>
          <p className="help" style={{ marginTop: 16, textAlign: "center" }}>
            By siging in, you agree to FooMoo’s{" "}
            <a href={`https://www.foo-moo.com/serviceterms`} target="_blank">
              Terms of Use
            </a>{" "}
            and{" "}
            <a href={`https://www.foo-moo.com/privacypolicy`} target="_blank">
              Privacy Notice
            </a>
            .
          </p>
          <p className="tip">
            <Typography.Text>
              Already have an account?
              <Link to="/login" className="tip2">
                Login in{" "}
              </Link>
              and Add Other Children or School
            </Typography.Text>
          </p>
        </Form>
      </div>
    </div>
  );
});
