import React, { useEffect, useState } from "react";
import "./App.scss";
import { observer } from "mobx-react";
import { Layout, Menu } from "antd";
import { Route, useHistory, useLocation } from "react-router";
import { Home } from "./home/Home";
import { Admin } from "./admin/Admin";
import { Account } from "./account/Account";
import { MobileMenu } from "../components/mobile/MobileMenu";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { History } from "./admin/History";
import { Teach } from "./teacher/Teach";
import { appStore } from "../store/AppStore";
import { ParentFundraisers } from "./parent/fundraiser/ParentFundraisers";
import { ChallengeDlg } from "./teacher/components/ChallengeDlg/ChallengeDlg";
import { ClassDlg } from "./teacher/components/ClassDlg";
import { FundraiserDlg } from "./admin/fundraiser/FundraiserDlg";
import { teacher } from "../store/domains/Teacher";
import { Fundraiser } from "./fundraiser/Fundraiser";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || "");

const { Header, Content } = Layout;
const { SubMenu } = Menu;

export const App = observer(() => {
  const history = useHistory();
  const location = useLocation();
  const [curMenu, setCurMenu] = useState("/");

  const { firstName, isTeacher, isAdmin, isParent } = appStore.me;

  useEffect(() => {
    setCurMenu(location.pathname);
  }, [location]);

  const onMenu = (e) => {
    if (e.key === "logout") {
      appStore.logout().then(() => {
        history.push("/");
        window.location.reload();
      });
    } else if (e.key === "newevent") {
      appStore.teach.challenge.show();
    } else if (e.key === "newfund") {
      appStore.admin.fundraising.show();
    } else {
      history.push(e.key);
    }
  };
  return (
    <Layout className="App">
      <Header className="headerWrap" style={{ position: "fixed", zIndex: 1000, width: "100%" }}>
        <div className="container header">
          <div className="logo"></div>
          <Menu className="menus" mode="horizontal" onClick={onMenu} selectedKeys={[curMenu]}>
            {(isTeacher || isAdmin) && (
              <Menu.Item key="/" className="menu">
                Home
              </Menu.Item>
            )}
            {isTeacher && teacher.schools.length > 0 && (
              <SubMenu key="/teach" title="Challenge Event" className="menu">
                <Menu.Item key={`newevent`} className="menu">
                  New Event
                </Menu.Item>
                <Menu.Item key={`/teach/events`} className="menu">
                  Event List
                </Menu.Item>
                <Menu.Item key={`/teach/classes`} className="menu">
                  Student List
                </Menu.Item>
              </SubMenu>
            )}
            {isAdmin && (
              <SubMenu key="/fund" title="Fundraiser" className="menu">
                <Menu.Item key={`/fund/active`} className="menu">
                  Manage Active Fundraiser
                </Menu.Item>
                <Menu.Item key={`newfund`} className="menu">
                  Start New Fundraiser
                </Menu.Item>
                <Menu.Item key={`/fund/history`} className="menu">
                  Fundraising History
                </Menu.Item>
                {isParent && (
                  <Menu.Item key="/fundraiser" className="menu">
                    Participate in Fundraiser
                  </Menu.Item>
                )}
              </SubMenu>
            )}
            {isParent && !isAdmin && (
              <Menu.Item key="/fundraiser" className="menu">
                Fundraiser
              </Menu.Item>
            )}
            <Menu.Item key="/account" className="menu">
              Account
            </Menu.Item>
            <Menu.Item key="logout" className="menu">
              Log Out
            </Menu.Item>
          </Menu>
          <div className="user-name">{firstName}</div>
          <MobileMenu logout={appStore.logout} me={appStore.me} />
        </div>
      </Header>
      <Content className="appBody">
        <Elements stripe={stripePromise}>
          <Layout className="app-bg">
            <Route path="/" exact component={isTeacher || isAdmin ? Home : ParentFundraisers} />
            <Route path="/teach" component={Teach} />
            <Route path="/fundraiser" exact component={ParentFundraisers} />
            <Route path="/fund/active" exact component={Admin} />
            <Route path="/fund/history" exact component={History} />
            <Layout className="container">
              <Route path="/admin" exact component={Admin} />
              <Route path="/admin/history" component={History} />
              <Route path="/account" component={Account} />
            </Layout>
          </Layout>
        </Elements>
        <ChallengeDlg></ChallengeDlg>
        <ClassDlg></ClassDlg>
        <FundraiserDlg></FundraiserDlg>
      </Content>
    </Layout>
  );
});
