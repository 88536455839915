import React, { useEffect } from "react";
import "./DonateDlg.scss";
import { Button, Form, Input, Modal, Tag } from "antd";
import CardSection from "../../../account/CardSection";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { FrDonate } from "../../../../graphql/me";
import { useMutation } from "@apollo/react-hooks";
import { showError, showSuccess } from "../../../../common/Utils";
import { observer } from "mobx-react";
import { parent } from "../../../../store/domains/Parent";
import { appStore } from "../../../../store/AppStore";
import { MoneyInput } from "../../../../components/MoneyInput";
import { ApolloError } from "apollo-client";

export const DonateDlg = observer(() => {
  const stripe = useStripe();
  const elements = useElements();
  const [form] = Form.useForm();
  const [frDonate] = useMutation(FrDonate);

  const { donateStore } = appStore.parent;

  useEffect(() => {
    parent.loadPayMethods();
  }, []);

  useEffect(() => {
    if (donateStore.visible) {
      form.setFieldsValue({ amount: donateStore.amount });
    }
  }, [donateStore.visible]);

  const onChange = (e) => {
    console.log("e", e);
    donateStore.setMsg("");
  };

  const onDonate = () => {
    if (!stripe) {
      showError();
      return;
    }
    if (!donateStore.payment && !elements) {
      showError();
      return;
    }
    form.validateFields().then(async (values) => {
      donateStore.saving = true;
      // const {
      //   data: { donate },
      // }: any = await frDonate({
      //   variables: {
      //     input: {
      //       fundId: donateStore.fundId,
      //       amount: values.amount,
      //       payment: donateStore.payment ? donateStore.payment.id : null,
      //     },
      //   },
      // });

      let hasErr = false;
      const resp = await frDonate({
        variables: {
          input: {
            fundId: donateStore.fundId,
            amount: values.amount,
            payment: donateStore.payment ? donateStore.payment.id : null,
          },
        },
      }).catch((err: ApolloError) => {
        console.log("errrrr", err);
        if (err.graphQLErrors) {
          console.log("err", err.graphQLErrors?.[0]?.message);
          showError(err.graphQLErrors?.[0]?.message);
        }
        hasErr = true;
        return { data: {} };
      });

      donateStore.saving = false;

      if (hasErr) return;

      const { donate } = resp.data;

      if (donateStore.payment) {
        donateStore.saving = false;
        donateStore.close();
        // showSuccess(
        //   "Thank you for your generosity! You can find donation history in your Account for your tax records."
        // );
        Modal.success({
          // title: "Verification in Progress",
          content: "Thank you for your generosity! You can find donation history in your Account for your tax records.",
          okText: "Close",
          okType: "default",
          centered: true,
        });
        setTimeout(() => {
          parent.loadParent();
          setTimeout(() => {
            parent.loadParent();
          }, 3000);
        }, 3000);
        return;
      }
      const data = donateStore.payment
        ? undefined
        : {
            payment_method: { card: elements?.getElement(CardElement) as any, billing_details: values.billingDetails },
          };
      const result = await stripe.confirmCardPayment(donate.clientSecret, data);
      donateStore.saving = false;

      if (result.error) {
        console.log(result.error.message);
        donateStore.setMsg("Cannot process your payment. Please check the information or try another card.");
      } else {
        if (result?.paymentIntent?.status === "succeeded") {
          // Show a success message to your customer
          // There's a risk of the customer closing the window before callback
          // execution. Set up a webhook or plugin to listen for the
          // payment_intent.succeeded event that handles any business critical
          // post-payment actions.
          showSuccess();
          donateStore.close();
          setTimeout(() => {
            parent.loadParent();
          }, 3000);
        }
      }
    });
  };

  return (
    <Modal
      visible={donateStore.visible}
      onCancel={() => donateStore.close()}
      title="Make a Donation"
      className="DonateDlg"
      footer={null}
      width={600}
      centered
      maskClosable={false}
      bodyStyle={{ padding: "20px 24px 32px 24px" }}
    >
      <Form layout="vertical" form={form} initialValues={{ amount: donateStore.amount }}>
        <Form.Item
          name="amount"
          rules={[
            { required: true, message: "Please input donate amount" },
            { type: "number", min: 1, message: "Donation amount cannot be less than $1.00." },
          ]}
          style={{ textAlign: "center" }}
        >
          <MoneyInput
            className="donate-input"
            value={donateStore.amount}
            max={9999.99}
            onChange={(value) => (donateStore.amount = Number(value))}
            style={{ color: "#191a1d", fontSize: "28px", fontWeight: 600, textAlign: "center" }}
          ></MoneyInput>
        </Form.Item>
        {donateStore.payment !== null ? (
          <div className="tip" style={{ textAlign: "center" }}>
            <div className="card-list">
              {parent.payMethods.map((p: any, index) => (
                <div
                  onClick={() => donateStore.setPayIndex(index)}
                  className={`card-item ${index === donateStore.paymentIndex ? "selected" : ""}`}
                  key={p.id}
                >
                  {p.brand} **** {p.last4}{" "}
                  {p.isDefault && (
                    <Tag style={{ marginLeft: 24 }} color="processing">
                      default
                    </Tag>
                  )}
                </div>
              ))}
            </div>
            Donate with {donateStore.payment?.brand} card ****{donateStore.payment?.last4}.
            <Button type="link" onClick={() => donateStore.setPayIndex(null)}>
              Change card.
            </Button>
          </div>
        ) : (
          <>
            <Form.Item
              label="Card Holder Name"
              name={["billingDetails", "name"]}
              rules={[{ required: true, message: "Please input your name" }]}
            >
              <Input></Input>
            </Form.Item>
            <Form.Item
              label="Credit or Debit Card"
              required
              help={donateStore.msg}
              validateStatus={donateStore.msg ? "error" : "success"}
            >
              <CardSection onChange={onChange} />
            </Form.Item>
          </>
        )}

        <Form.Item style={{ textAlign: "center" }}>
          <Button block type="primary" className="btn" onClick={onDonate} loading={donateStore.saving}>
            Donate
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
});
