import React, { useState, useEffect } from "react";
// import "./Home.scss";
import { FrCard } from "../../../../components/FrCard";
import { DonateViewList, DonateEditList } from "./DonateBox";
import { DonateDlg } from "./DonateDlg";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { FrDonate, meGql } from "../../../../graphql/me";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { appStore } from "../../../../store/AppStore";
import { isMobile } from "react-device-detect";

export const YourDonation = ({ fundId, end, donateCount, stats, historic, suggested }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { donateStore } = appStore.parent;

  const { userDonated } = stats || {};

  const { refetch } = useQuery<any>(meGql.me);
  const [payment, setPayment] = useState<any>(null);
  const { data: { payMethods } = { payMethods: [] }, refetch: refetchPaymemts } = useQuery<any>(meGql.payMethods);
  const [frDonate] = useMutation(FrDonate);

  useEffect(() => {
    setPayment(payMethods.length > 0 ? payMethods[0] : null);
  }, [payMethods]);

  return (
    <FrCard
      title={userDonated ? "Your Donation" : "Make a Donation"}
      bodyStyle={{ padding: "0 18px", height: isMobile ? "auto" : 320 }}
      style={{ marginTop: 0 }}
      
    >
      {userDonated ? (
        <>
          <DonateViewList end={end} donateCount={donateCount} stats={stats} />
          <div
            className="fcc donateBtn your-donation-btn"
            onClick={() => appStore.parent.donateStore.show(fundId, undefined)}
          >
            <div className="donateIcon" />
            <span>Donate More</span>
          </div>
        </>
      ) : (
        <DonateEditList suggested={suggested} onDonate={(amount) => appStore.parent.donateStore.show(fundId, amount)} />
      )}

      <DonateDlg />
    </FrCard>
  );
};
