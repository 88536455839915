import React from "react";
import "./Account.scss";
import { FrCard } from "../../components/FrCard";
import { StudentDlg } from "../../components/StudentDlg";
import { observer } from "mobx-react";
import { parent } from "../../store/domains/Parent";
import { appStore } from "../../store/AppStore";
import { Alert, Button } from "antd";

export const Students = observer(() => {
  return (
    <FrCard title="Child Information" onAdd={() => appStore.parent.editChild.edit()}>
      <table className="students">
        <tbody>
          {parent.children.map((s: any, i) => (
            <>
              <tr key={i + ""}>
                <th>{s.firstName}</th>
                <td>{s.school?.name || s.pending?.schoolName}</td>
                <td>{s.grade || s.pending?.grade}</td>
              </tr>
              {s.pending && (
                <tr>
                  <td colSpan={3} style={{ height: 20, paddingRight: 16 }}>
                    <Alert
                      message=""
                      description="Verification in Progress"
                      type="warning"
                      style={{ padding: "0 10px", marginTop: -12 }}
                    />
                  </td>
                </tr>
              )}
              {s.hasReject && !s.pending && (
                <tr>
                  <td colSpan={3} style={{ height: 20, paddingRight: 16 }}>
                    <Alert
                      message=""
                      description="Some of the information for this child didn't pass verification. You can delete it and resubmit here, or edit it on the FooMoo mobile app. (Same user name and password as for this website.)"
                      type="error"
                      style={{ padding: "0 10px", marginTop: -12 }}
                      action={
                        <Button
                          style={{ marginTop: 24 }}
                          size="small"
                          danger
                          onClick={() => appStore.parent.delChild(s)}
                        >
                          Delete
                        </Button>
                      }
                    />
                  </td>
                </tr>
              )}
            </>
          ))}
        </tbody>
      </table>
      <StudentDlg />
    </FrCard>
  );
});
