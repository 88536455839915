import React, { useEffect, useState } from "react";
import { Form, Input, Button, message, Typography } from "antd";
import Auth from "@aws-amplify/auth";
import { Link, useHistory } from "react-router-dom";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import "./LoginPage.scss";
import { observer } from "mobx-react";
import { appStore } from "../../store/AppStore";
import { showError } from "../../common/Utils";
import { AuthLayout } from "./AuthLayout";

export const LoginPage = observer(() => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [requireNewPwd, setRequireNewPwd] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (appStore.isLogin == true) {
      history.push("/");
      appStore.loadMe();
      return;
    }
    Auth.currentAuthenticatedUser({
      bypassCache: true,
    }).then((user) => {
      history.push("/");
      appStore.loadMe();
    });
  }, []);

  const onFinish = () => {
    form.validateFields().then((values) => {
      setLoading(true);
      if (requireNewPwd) {
        Auth.completeNewPassword(user, values.newPassword, [])
          .then(() => {
            appStore.isLogin = true;
            appStore.loadMe();
            history.push("/");
          })
          .catch((err) => {
            // message.error(err.message);
            showError(err.message);
          })
          .finally(() => setLoading(false));
      } else {
        Auth.signIn(values.username.trim(), values.password)
          .then((user) => {
            if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
              setUser(user);
              setRequireNewPwd(true);
            } else {
              appStore.isLogin = true;
              appStore.loadMe();
              history.push("/");
            }
          })
          .catch((err) => {
            message.error(err.message);
            if (err.message === "Password reset required for the user") {
              history.push("/reset");
            }
          })
          .finally(() => setLoading(false));
      }
    });
  };

  return (
    <AuthLayout>
      <div className="LoginPage">
        <div className="LoginLeft fcc">
          <div className="wrap">
            <div className="title-wrap">
              <div className="title">Community Gateway</div>
              <div className="sub-title">Building the Heart of a Community</div>
            </div>
            <div className="tip">———— Created by Parents for Parents</div>
          </div>
        </div>
        <div className="LoginRight fcc">
          <Form form={form} layout="vertical" name="basic" initialValues={{}} hideRequiredMark={true} size="large">
            {requireNewPwd ? (
              <>
                <div className="invite-header" style={{ marginBottom: 60 }}>
                  New Account Setup
                </div>
                <Form.Item
                  label="New Password"
                  name="newPassword"
                  // dependencies={["password2"]}
                  rules={[
                    { required: true, message: "Please input your new password!", type: "string", min: 6 },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || (/\d+/.test(value) && /[a-z]+/.test(value) && /[A-Z]+/.test(value))) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          "Passwords do not match or meet the requirement (at least one upper case letter, one lower case letter and one number)."
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    // placeholder="Enter your new password"
                    prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                  />
                </Form.Item>
                <Form.Item
                  label="Comfirm Password"
                  name="password2"
                  dependencies={["newPassword"]}
                  rules={[
                    { required: true, message: "Please confirm your password!" },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue("newPassword") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject("The two passwords that you entered do not match!");
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    // placeholder="Enter your comfirm password"
                    prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                  />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" className="btn" onClick={onFinish} block htmlType="submit" loading={loading}>
                    Log in
                  </Button>
                  {/* <p className="tip">
                  Not a member yet? <Link to="/reg">Register now</Link>
                </p> */}
                </Form.Item>
                <p className="help" style={{ marginTop: 16, textAlign: "center" }}>
                  By siging in, you agree to FooMoo’s{" "}
                  <a href={`https://www.foo-moo.com/serviceterms`} target="_blank">
                    Terms of Use
                  </a>{" "}
                  and{" "}
                  <a href={`https://www.foo-moo.com/privacypolicy`} target="_blank">
                    Privacy Notice
                  </a>
                  .
                </p>
              </>
            ) : (
              <>
                <div className="invite-header" style={{ marginBottom: 60 }}>
                  Login in
                </div>
                <Form.Item
                  label="Email Address"
                  name="username"
                  rules={[{ required: true, message: "Please input your email address!", type: "email" }]}
                >
                  <Input prefix={<MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />} />
                </Form.Item>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[{ required: true, message: "Please input your password!" }]}
                >
                  <Input.Password prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />} />
                </Form.Item>
                <Form.Item style={{ marginTop: 40 }}>
                  <Button type="primary" className="btn" onClick={onFinish} block htmlType="submit" loading={loading}>
                    Sign in
                  </Button>
                </Form.Item>

                <p className="tip" style={{ marginTop: 16, textAlign: "center" }}>
                  Not a member yet?
                  <Link to="/reg" className="tip2">
                    Register now
                  </Link>
                </p>

                <p className="help" style={{ marginTop: 16, textAlign: "center" }}>
                  By siging in, you agree to FooMoo’s{" "}
                  <a href={`https://www.foo-moo.com/serviceterms`} target="_blank">
                    Terms of Use
                  </a>{" "}
                  and{" "}
                  <a href={`https://www.foo-moo.com/privacypolicy`} target="_blank">
                    Privacy Notice
                  </a>
                  .
                </p>
                <p className="tip">
                  <Typography.Text>
                    <Link to="/forgot" className="tip2">
                      Forgot password
                    </Link>
                  </Typography.Text>
                </p>
              </>
            )}
          </Form>
        </div>
      </div>
    </AuthLayout>
  );
});
