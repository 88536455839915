import { makeAutoObservable, action, computed } from "mobx";
import { Api } from "../../common/Api";
import { parent } from "./Parent";

export class Fundraising {
  id;
  type;
  name;
  start;
  end;
  proceeds;
  status;
  suggested;
  target;
  description;
  count;
  donateCount;
  reached;
  totalStudents;
  familyCount;
  totalParticipated;
  shareCode;
  joinedStudents;
  reachedStudent;
  organizer;
  goalCompletedCount;
  top10Families: any[];
  goals: any[];
  familyDonates: any[];

  userStats = {
    userDonated: 0,
    extended: 0,
    ranking: 0,
  };

  constructor(model) {
    makeAutoObservable(this, {
      reachPercent: computed,
      familyPercent: computed,
      studentPercent: computed,
      goalsPercent: computed,
    });
    this.id = model.id;
    this.type = model.type;
    this.name = model.name;
    this.start = model.start;
    this.end = model.end;
    this.proceeds = model.proceeds;
    this.status = model.status;
    this.suggested = model.suggested;
    this.target = model.target;
    this.description = model.description;
    this.count = model.count;
    this.reached = model.reached;
    this.donateCount = model.donateCount;
    this.totalStudents = model.totalStudents;
    this.familyCount = model.familyCount;
    this.totalParticipated = model.totalParticipated;
    this.joinedStudents = model.joinedStudents;
    this.reachedStudent = model.reachedStudent;
    this.organizer = model.organizer;
    this.top10Families = model.top10Families;
    this.userStats = model.userStats;
    this.goals = model.goals;
    this.shareCode = model.shareCode;
    this.goalCompletedCount = model.goalCompletedCount;
    this.familyDonates = model.familyDonates || [];
  }

  get reachPercent() {
    const { target = 0, reached = 0 } = this;
    return target === 0 ? 0 : parseInt(((reached * 1.0) / target).toFixed(0));
  }

  get familyPercent() {
    const { familyCount = 0, totalParticipated = 0 } = this;
    return totalParticipated === 0 ? 0 : (familyCount * 100) / totalParticipated;
  }

  get studentPercent() {
    const { familyCount = 0, totalStudents = 0 } = this;
    return totalStudents === 0 ? 0 : (familyCount * 100) / totalStudents;
  }

  get goalsPercent() {
    const { goalCompletedCount = 0, totalStudents = 0 } = this;
    return totalStudents === 0 ? 0 : (goalCompletedCount * 100) / totalStudents;
  }

  @action
  onChangeGoals(i, goals) {
    console.log("onChangeGoals", i, goals, this.goals[i]);
    Api.parent.updateFundGoals(this.goals[i].id, this.goals[i].goals).then(() => {
      return parent.loadParent();
    });
  }
}
