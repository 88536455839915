import { observer } from "mobx-react";
import React, { useState, useEffect } from "react";
import { ImgCard } from "../../components/ImgCard";
import { appStore } from "../../store/AppStore";
import "./TeacherIndex.scss";
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";

const rpx = (px) => `${(px * 100) / 750}vw`;

export const TeacherIndex = observer(() => {
  const mobile = {
    card1: {
      width: rpx(670),
      height: rpx(460),
    },
    card2: {
      width: rpx(670),
      height: rpx(1000),
    },
    btn1: {
      width: rpx(200),
      height: rpx(64),
      left: rpx(235),
      top: rpx(208),
    },
    btn2: {
      width: rpx(200),
      height: rpx(64),
      left: rpx(14),
      top: rpx(498),
    },
    card21: {
      width: rpx(284),
      height: rpx(200),
      left: rpx(-28),
      top: rpx(330),
    },
    card22: {
      width: rpx(284),
      height: rpx(200),
      right: rpx(-28),
      top: rpx(330),
    },
    card23: {
      width: rpx(262),
      height: rpx(492),
      right: rpx(204),
      bottom: rpx(-89),
    },
    card3: {
      width: rpx(670),
      height: rpx(920),
    },
    card31: {
      width: rpx(284),
      height: rpx(200),
      left: rpx(-28),
      top: rpx(305),
    },
    card32: {
      width: rpx(224),
      height: rpx(348),
      right: rpx(-28),
      top: rpx(272),
    },
    btn3: {
      width: rpx(200),
      height: rpx(64),
      left: rpx(236),
      top: rpx(208),
    },
    card4: {
      width: rpx(670),
      height: rpx(300),
    },
  };
  return isMobile ? (
    <div className="container teacher-index-mobile">
      <ImgCard bg="card1" width={mobile.card1.width} height={mobile.card1.height}></ImgCard>
      <ImgCard bg="card2" width={mobile.card2.width} height={mobile.card2.height}>
        <ImgCard bg="card21" style={mobile.card21}></ImgCard>
        <ImgCard bg="card22" style={mobile.card22}></ImgCard>
        <ImgCard bg="card23" style={mobile.card23}></ImgCard>
        {appStore.me.isTeacher && (
          <ImgCard
            bg="start"
            style={mobile.btn1}
            onClick={() => appStore.teach.schoolsStore.editGroup.show()}
          ></ImgCard>
        )}
        {appStore.me.isTeacher && (
          <ImgCard bg="start" style={mobile.btn2} onClick={() => appStore.teach.challenge.show()}></ImgCard>
        )}
      </ImgCard>
      <ImgCard bg="card3" width={mobile.card3.width} height={mobile.card3.height}>
        <ImgCard bg="card31" style={mobile.card31}></ImgCard>
        <ImgCard bg="card32" style={mobile.card32}></ImgCard>
        {appStore.me.isAdmin && (
          <ImgCard bg="start" style={mobile.btn3} onClick={() => appStore.admin.fundraising.show()}></ImgCard>
        )}
      </ImgCard>
      <ImgCard bg="card4" width={mobile.card4.width} height={mobile.card4.height}></ImgCard>
    </div>
  ) : (
    <div className="container teacher-index">
      <ImgCard bg="card1" width={1196} height={760}>
        <ImgCard bg="card11" width={300} height={360}></ImgCard>
        <ImgCard bg="card12" width={284} height={200}></ImgCard>
        <ImgCard bg="card13" width={200} height={362}></ImgCard>
        {appStore.me.isTeacher && (
          <div className="btn btn-new-class" onClick={() => appStore.teach.schoolsStore.editGroup.show()}>
            Start
          </div>
        )}
        {appStore.me.isTeacher && (
          <div className="btn btn-new-event" onClick={() => appStore.teach.challenge.show()}>
            Start
          </div>
        )}
      </ImgCard>

      <ImgCard bg="card2" width={1196} height={760}>
        <ImgCard bg="card21" width={284} height={200}></ImgCard>
        <ImgCard bg="card22" width={300} height={200}></ImgCard>
        {appStore.me.isAdmin && (
          <div className="btn btn-new-fundraising" onClick={() => appStore.admin.fundraising.show()}>
            Start
          </div>
        )}
      </ImgCard>
    </div>
  );
});
