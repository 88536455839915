import { observer } from "mobx-react";
import React from "react";
import { appStore } from "../../../../store/AppStore";
import "./engagement.scss";

export const ParentPreview = observer(() => {
  const store = appStore.teach.challenge;
  const name = store.model.name === "-" ? store.model.customName : store.addGroupName + store.model.name;

  return (
    <div className="preview-panel">
      <div className="phone phone1">
        <div className="phone-body">
          <div className="phone-body-p1"></div>
          <div className="phone-body-p2">
            <div className="p1-user">{appStore.me.name}</div>
            <div className="p1-title">{name}</div>
            <div className="p1-content">{store.model.description}</div>
          </div>
          <div className="phone-body-p3">
            <div>{store.model.range[0].format("MMM DD")}</div>
          </div>
          <div className="phone-body-p4"></div>
        </div>
      </div>
      <div className="phone phone2">
        <div className="phone-body">
          <div className="phone-body-p1"></div>
          <div className="phone-body-p2">
            <div className="part1">
              <div className="p1-title">{name}</div>
              <div className="p1-date">Deadline: {store.model.range[1].format("MMM DD")} </div>
              <div className="p1-content">{store.model.description}</div>
            </div>
            {store.model.goal.enabled && (
              <div className="part2">
                <div className="part2-title">Goals </div>
                <div className="part2-content">{store.model.goal.description}</div>
                {store.model.goal.items
                  .filter((x: any) => x.checked)
                  .map((s: any, i) => (
                    <div key={i + ""} className="part2-content-item">
                      {s.value}
                    </div>
                  ))}
              </div>
            )}

            {store.model.workSubmission.enabled && (
              <div className="part2">
                <div className="part2-title">Work Submission </div>
                <div className="part2-content">{store.model.workSubmission.description}</div>
                {store.model.workSubmission.items
                  .filter((x: any) => x.checked)
                  .map((s: any, i) => (
                    <div key={i + ""} className="part2-content-item">
                      {s.value}
                    </div>
                  ))}
              </div>
            )}

            {store.model.parentHelp && (
              <div className="part2">
                <div className="part2-title">How can parent's help </div>
                <div className="part2-content">{store.model.parentHelp}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});
